import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
import { enter, leave } from 'el-transition'

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ['frame']

  connect () {
    if (!this.element.classList.contains('hidden')) {
      enter(this.element)
      this.frameTargets.forEach(e => { enter(e) })
    }
  }

  close (event) {
    event.preventDefault()
    this.#leave()
  }

  submitEnd (event) {
    if (event.detail.success) {
      this.#leave()
      if (!this.element.querySelector('[data-modal-no-page-reload]')) Turbo.visit(window.location.href)
    }
  }

  #leave () {
    this.frameTargets.forEach(e => { leave(e) })
    leave(this.element)
  }
}
