import M from 'materialize-css/dist/js/materialize'

export class PlacementDialog {
  _dlgElem : HTMLElement;

  onCancel : () => void;

  constructor(elem : HTMLElement) {
    this._dlgElem = elem;

    elem.querySelector('.rve-cancel-dlg').addEventListener('click', (e) => {
      e.preventDefault();
      M.Modal.getInstance(elem).close();
      if (this.onCancel) {
        this.onCancel();
      }
    }, true);

    elem.querySelectorAll('.main-placement').forEach(e => e.addEventListener(
      'click', this.onMainPlacementClick.bind(this), true));
    elem.querySelectorAll('.sub-placement').forEach(e => e.addEventListener(
      'click', this.onSubPlacementClick.bind(this), true));

    M.updateTextFields();
    M.Modal.init(this._dlgElem, {dismissible: false});
    M.Modal.getInstance(this._dlgElem).open();
  }

  private onMainPlacementClick(event) {
    let label = event.currentTarget.getAttribute('data-label');
    (this._dlgElem.querySelector("input[name='placement']") as HTMLInputElement).value = label;
    M.updateTextFields();

    this._dlgElem.querySelectorAll('.selected').forEach(e => e.classList.remove('selected'));

    event.currentTarget.classList.add('selected');
    let subList = this._dlgElem.querySelector(
      ".sub-list[data-parent-index='" + event.currentTarget.getAttribute('data-index') + "']");
    if (subList) {
      subList.classList.add('selected');
    }
  }

  private onSubPlacementClick(event) {
    let mainLabel = this._dlgElem.querySelector('.main-placement.selected').
      getAttribute('data-label');
    let label = event.currentTarget.getAttribute('data-label');
    let e = this._dlgElem.querySelector("input[name='placement']") as HTMLInputElement;
    e.value = mainLabel + ', ' + label;
    M.updateTextFields();
    this._dlgElem.querySelectorAll('.sub-list .selected').forEach(e => e.classList.remove('selected'));
    event.currentTarget.classList.add('selected');
  }
};

(window as any).elias_create_table_placement_dialog = function(html) {
  let c = document.getElementById('dialog-container');
  c.innerHTML = html;
  new PlacementDialog(c.querySelector('.modal'));
}
