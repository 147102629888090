import Flash from './flash.ts'

class CardRecordsReconcile {
  _table : HTMLElement;
  constructor(table) {
    this._table = table;
    this._table.querySelectorAll('.record').
      forEach(e => e.addEventListener('click', this.clickRecord.bind(this)));

    document.getElementById('diff-btn').
      addEventListener('click', this.diff.bind(this), true);
    document.getElementById('submit-btn').
      addEventListener('click', this.submit.bind(this), true);
  }

  private clickRecord(event) {
    let row = event.target.closest('tr.record');
    if (row.getAttribute('data-selected') == '1') {
      row.setAttribute('data-selected', '0');
    } else {
      row.setAttribute('data-selected', '1');
    }
    this.updateSummary();
  }

  private updateSummary() {
    let sum = 0;
    this._table.querySelectorAll(".record[data-selected='1']").forEach((e) => {
      sum += parseInt(e.getAttribute('data-amount'));
    });
    let sign = '';
    if (sum < 0) {
      sum = -sum;
      sign = '-';
    }
    document.getElementById('sum-selected').innerText =
      sign + Math.floor(sum / 100).toLocaleString('sv') + ',' + (sum % 100).toString();
  }

  private submit(event) {
    let href = event.target.getAttribute('href');
    let ids = [];
    this._table.querySelectorAll(".record[data-selected='1']").forEach((e) => {
      ids.push(e.getAttribute('data-id'));
    });

    fetch(href, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ids: ids}),
    })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        Flash.failure(data.error);
      } else {
        Flash.success(data.success);
        this._table.querySelectorAll(".record[data-selected='1']").forEach((e) => {
          e.remove();
        });
      }
    })
    .catch((error) => {
      Flash.failure('Ett tekniskt fel inträffade');
      console.error('Error:', error);
    });

    event.preventDefault();
  }

  private diff(event) {
    let href = event.target.getAttribute('href');
    href += '?x=1';
    this._table.querySelectorAll(".record[data-selected='1']").forEach((e) => {
      href += '&ids%5B%5D=' + e.getAttribute('data-id');
    });
    window.location = href;
    event.preventDefault();
  }
};

document.addEventListener('turbo:load', () => {
  let e = document.getElementById('card-records-reconcile');
  if (e) {
    new CardRecordsReconcile(e);
  }
});

