import { Controller } from '@hotwired/stimulus'
import { Litepicker } from 'litepicker'

// Connects to data-controller="gig-date-selector"
export default class extends Controller {
  static targets = ['startDate', 'stopDate']
  static values = {
    mode: String // Should be set to single or multiple
  }

  connect () {
    this.#setupPicker()
  }

  disconnect () {
    this.picker.destroy()
    this.picker = undefined
  }

  toggleMultiDay () {
    this.modeValue = this.modeValue === 'single' ? 'multiple' : 'single'
    this.#setupPicker()
  }

  #setupPicker () {
    if (this.picker) {
      this.picker.destroy()
    }
    const params = {
      element: this.startDateTarget,
      zIndex: 99999,
      singleMode: this.modeValue === 'single',
      showWeekNumbers: true
    }
    if (!params.singleMode) {
      params.elementEnd = this.stopDateTarget
      params.startDate = this.startDateTarget.value
      params.endDate = this.stopDateTarget.value || params.startDate
      if (params.endDate < params.startDate) {
        params.endDate = params.startDate
      }
    }
    this.picker = new Litepicker(params)
    this.picker.on('before:show', this.savePreValues.bind(this))
    this.picker.on('hide', this.dispatchChangeEvents.bind(this))
  }

  savePreValues (el) {
    this.preStartValue = this.startDateTarget.value
    this.preStopValue = this.stopDateTarget.value
  }

  dispatchChangeEvents () {
    if (this.preStartValue !== this.startDateTarget.value ||
      (this.modeValue === 'multiple' && this.preStopValue !== this.stopDateTarget.value)) {
      M.updateTextFields()
      this.dispatch('change')
    }
  }
}
