import consumer from './consumer'
import { Turbo } from '@hotwired/turbo-rails'

consumer.subscriptions.create({ channel: 'UserChannel' }, {
  received (data) {
    if (data.redirect_to) {
      this.redirectTo(data.redirect_to, data.options)
    }
  },

  redirectTo (href, options = {}) {
    Turbo.visit(href, options)
  }
})
