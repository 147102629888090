import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['coverImagefield', 'destroyField']

  setCoverImage (event) {
    event.preventDefault()
    this.coverImagefieldTarget.value = true

    document.querySelectorAll('#room-type-form .photo-container').forEach((ele, index) => {
      const coverImageField = ele.querySelector('.coverImageField')

      if (this.coverImagefieldTarget !== coverImageField) {
        ele.querySelector('.coverImageField').value = false
      }
    })
    document.querySelector("button[data-abstract-upload-target='photosSubmitBtn']").click()
  }

  destroyImage (event) {
    event.preventDefault()
    event.stopPropagation()
    this.destroyFieldTarget.value = true
    this.element.hidden = true
    document.querySelectorAll('#room-type-form .photo-container').forEach((ele, index) => {
      ele.querySelector('.positionField').value = index
    })
    document.querySelector("button[data-abstract-upload-target='photosSubmitBtn']").click()
  }
}
