import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    debounceTimeout: { type: Number, default: 1000 }
  }

  disconnect () {
    if (this.debounceTimerID) {
      clearTimeout(this.debounceTimerID)
      this.debounceTimerID = null
    }
  }

  dataChanged (event) {
    if (this.debounceTimerID) {
      clearTimeout(this.debounceTimerID)
    }
    this.debounceTimerID = setTimeout(
      () => { this.element.requestSubmit() }, this.debounceTimeoutValue)
  }
}
