import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'

// Connects to data-controller="element-remover"
export default class extends Controller {
  static values = {
    timer: Number // Set to milliseconds for automatic remove after timeout
  }

  connect () {
    enter(this.element)
    if (this.timerValue) {
      this.timeoutID = setTimeout(this.remove.bind(this), this.timerValue)
    }
  }

  disconnect () {
    if (this.timeoutID) {
      clearTimeout(this.timeoutID)
      this.timeoutID = null
    }
  }

  async remove () {
    await leave(this.element)
    this.element.remove()
  }
}
