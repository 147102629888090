import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="clearable-input"
export default class extends Controller {
  static targets = ['input']

  updateEmptyState () {
    if (this.inputTarget.value) {
      delete this.element.dataset.empty
    } else {
      this.element.dataset.empty = true
    }
  }

  clear () {
    this.inputTarget.value = ''
    this.inputTarget.dispatchEvent(new Event('input'))
  }
}
