import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="offer-preview"
export default class extends Controller {
  static targets = ['iframe', 'tab']

  connect () {
    this.iframeTarget.contentWindow.addEventListener('load', (event) => {
      const selectedUrl = this.iframeTarget.contentWindow.location.href
      this.tabTargets.forEach(elem => {
        if (selectedUrl.endsWith(elem.querySelector('a').getAttribute('href'))) {
          elem.dataset.selected = ''
        } else {
          delete elem.dataset.selected
        }
      })
    })
  }

  selectTab (event) {
    this.tabTargets.forEach(elem => {
      delete elem.dataset.selected
    })
    event.target.closest('li').dataset.selected = ''
  }
}
