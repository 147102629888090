import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="memoizer"
export default class extends Controller {
  connect () {
    this.boundBeforeInput = this.memoizeValue.bind(this)
    this.boundChange = this.fireChange.bind(this)
    this.element.addEventListener('beforeinput', this.boundBeforeInput)
    this.element.addEventListener('change', this.boundChange)
  }

  disconnect () {
    this.element.removeEventListener('beforeinput', this.boundBeforeInput)
    this.element.removeEventListener('change', this.boundChange)
  }

  memoizeValue () {
    if (!this.element.dataset.memoizedValue) {
      this.element.dataset.memoizedValue = this.element.value
    }
  }

  fireChange () {
    this.dispatch('change', {
      detail: {
        oldValue: this.element.dataset.memoizedValue,
        newValue: this.element.value
      }
    })
    delete this.element.dataset.memoizedValue
  }
}
