import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

// Connects to data-controller="gig-room-matrix"
export default class extends Controller {
  static values = {
    gigId: String,
    reloadUrl: String
  }

  static targets = ['startAtDate', 'startAtTime', 'stopAtDate', 'stopAtTime', 'multipleDays']

  async reload (event) {
    const response = await get(this.reloadUrlValue,
      { query: this.#get_params(), responseKind: 'json' })

    if (response.ok) {
      const state = await response.json
      state.rooms.forEach((room) => {
        const roomElem = this.element.querySelector(".room[data-room-id='" + room.id + "']")
        if (roomElem) {
          roomElem.setAttribute('data-availability', room.availability)
        }
      })

      this.element.querySelector('.room-matrix').classList.toggle('not-loaded', state.missing_data)
    }
  }

  roomClicked (event) {
    const target = event.target
    const input = target.querySelector('input')
    input.checked = !input.checked
    target.classList.toggle('selected', input.checked)
  }

  clearSelection (event) {
    event.preventDefault()
    this.element.querySelectorAll('.room-matrix .room').forEach((e) => {
      e.querySelector('input').checked = false
      e.classList.remove('selected')
    })
  }

  #get_params () {
    const params = {
      gig_id: this.gigIdValue,
      multiple_days: this.hasMultipleDaysTarget && this.multipleDaysTarget.checked ? 1 : 0,
      start_at_d: this.startAtDateTarget.value,
      start_at_t: this.startAtTimeTarget.value
    }
    if (this.hasStopAtDateTarget) {
      params.stop_at_d = this.stopAtDateTarget.value
    }
    if (this.hasStopAtTimeTarget) {
      params.stop_at_t = this.stopAtTimeTarget.value
    }
    return params
  }
}
