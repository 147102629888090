import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="modal-form"
export default class extends Controller {
  connect () {
    if (this.element.getAttribute('data-shown')) {
      // This is a cached version already shown, don't connect again
      return
    }
    this.element.setAttribute('data-shown', '1')

    M.Modal.init(this.element, { dismissible: false })
    M.Modal.getInstance(this.element).open()
    this.setupFormElements()

    this.boundBeforeStreamRenderHandler = this.beforeStreamRenderHandler.bind(this)
    document.addEventListener('turbo:before-stream-render', this.boundBeforeStreamRenderHandler)

    this.boundBeforeVisitHandler = this.beforeVisitHandler.bind(this)
    document.addEventListener('turbo:before-visit', this.boundBeforeVisitHandler)
  }

  disconnect () {
    document.removeEventListener('turbo:before-stream-render', this.boundBeforeStreamRenderHandler)
    document.removeEventListener('turbo:before-visit', this.boundBeforeVisitHandler)
  }

  close (event) {
    event.preventDefault()
    M.Modal.getInstance(this.element).close()
  }

  submitEnd (e) {
    if (e.detail.success) {
      M.Modal.getInstance(this.element).close()
    }
  }

  beforeVisitHandler (event) {
    M.Modal.getInstance(this.element).close()
  }

  beforeStreamRenderHandler (event) {
    const defaultRender = event.detail.render
    event.detail.render = function (streamElement) {
      defaultRender(streamElement)
      this.setupFormElements()
    }.bind(this)
  }

  setupFormElements () {
    if (M.Modal.getInstance(this.element).isOpen) {
      M.updateTextFields()
      M.FormSelect.init(this.element.querySelectorAll('select'), {})

      this.element.querySelectorAll('textarea').forEach((textarea) => {
        M.textareaAutoResize(textarea)
      })
    }
  }
}
