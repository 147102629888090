import M from 'materialize-css/dist/js/materialize'
import Flash from './flash.ts'
import Csrf from './csrf.ts'

class ConsumptionFormula {
  _root : HTMLElement;
  constructor(root) {
    this._root = root;
    this._root.addEventListener('change', this.onChange.bind(this));
  }

  private onChange(event) {
    let input = (event.target as HTMLInputElement);

    fetch(this._root.getAttribute('data-format-service'), {
      method: 'POST',
      headers: Csrf.add_header({'Content-Type': 'application/json'}),
      body: JSON.stringify({formula: input.value})
    })
    .then(response => response.text())
    .then(data => {
      document.getElementById('parsed-formula').innerHTML = data;
    })
    .catch((error) => {
      Flash.failure('Ett tekniskt fel inträffade');
      console.error('Error:', error);
    });
  }
};

document.addEventListener('turbo:load', () => {
  let e = document.getElementById('inventory-consumption-formula');
  if (e) {
    M.updateTextFields();
    new ConsumptionFormula(e);
  }
});
