import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="turbo-exceptions"
export default class extends Controller {
  static targets = ['messageTemplate']

  turboFrameMissing (event) {
    event.preventDefault()
    const message = this.messageTemplateTarget.content.cloneNode(true)
    document.getElementById('flash').appendChild(message)
  }
}
