import { Controller } from '@hotwired/stimulus'
import { Litepicker } from 'litepicker'

// Connects to data-controller="date-picker-v0"
export default class extends Controller {
  connect () {
    this.picker = new Litepicker({
      element: this.element,
      zIndex: 99999,
      singleMode: true,
      showWeekNumbers: true
    })

    this.picker.on('before:show', this.savePreDate.bind(this))
    this.picker.on('hide', this.dispatchChangeEvents.bind(this))
  }

  disconnect () {
    this.picker.destroy()
  }

  savePreDate (el) {
    this.preValue = this.element.value
  }

  dispatchChangeEvents () {
    if (this.preValue !== this.element.value) {
      M.updateTextFields()
      this.dispatch('change')
    }
  }
}
