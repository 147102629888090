export default class Csrf {
  private static get_token() {
    let meta_elem = document.querySelector("meta[name='csrf-token']");
    return meta_elem ? meta_elem.getAttribute('content') : ''
  }

  public static add_header(headers : {}) {
    headers['X-CSRF-Token'] = this.get_token();
    return headers;
  }
}
