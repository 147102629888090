import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="room-booking-form"
export default class extends Controller {
  static targets = ['assignedRoomLink']
  static values = { roomBookingId: String }

  updateAssignedRoomLink (event) {
    const newAssignedRoomId = event.currentTarget.value
    const newHref = new URL(this.assignedRoomLinkTarget.href)

    newHref.searchParams.set('hotel_booking[room_bookings_attributes][][id]', this.roomBookingIdValue)
    newHref.searchParams.set('hotel_booking[room_bookings_attributes][][assigned_room_id]', newAssignedRoomId)

    this.assignedRoomLinkTarget.href = newHref
    this.assignedRoomLinkTarget.click()
  }
}
