import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'count']

  connect () {
    this.updateCount()
  }

  search () {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.element.requestSubmit()
    }, 500)
  }

  resetSearch () {
    this.checkboxTargets.forEach(function (checkbox) {
      checkbox.checked = false
    })

    this.element.requestSubmit()
    this.updateCount()
  }

  updateCount () {
    const checkedCheckboxes = this.checkboxTargets.filter((checkbox) => checkbox.checked).length
    this.countTarget.innerText = checkedCheckboxes
  }
}
