import M from 'materialize-css/dist/js/materialize'
import { Litepicker } from 'litepicker'
import 'litepicker/dist/plugins/multiselect'
import Flash from './flash.ts'
import Utils from './utils.ts'
import TimeField from './time_field.ts'
import Csrf from './csrf.ts'

var planner : ShiftsPlanner;

function getShiftDialogTime(field : string)
{
  let v = (document.getElementById(field) as HTMLInputElement).value;
  let parts = v.split(':');
  return parseInt(parts[0]) * 60 + parseInt(parts[1]);
}

function setShiftDialogTime(field : string, minutes : number)
{
  let h = Math.floor(minutes / 60) % 24;
  let m = minutes % 60;
  let hs = '' + h;
  let ms = '' + m;
  if (h < 10) {
    hs = '0' + hs;
  }
  if (m < 10) {
    ms = '0' + ms;
  }
  (document.getElementById(field) as HTMLInputElement).value = hs + ':' + ms;
}

class TimeAxis {
  private _axisOrigoMinutes : number;
  private _axisLengthMinutes : number;
  private _ref : HTMLElement;

  constructor(origo : number, axisLength : number, refElem : HTMLElement) {
    this._axisOrigoMinutes = origo;
    this._axisLengthMinutes = axisLength;
    this._ref = refElem;
  }

  timeFromX(x : number) : number {
    return Math.round(
      (this._axisOrigoMinutes + x * this._axisLengthMinutes / this._ref.clientWidth) / 30) * 30;
  }

  xFromTime(t : number) : number {
    return this._ref.clientWidth * this.pctFromTime(t) / 100;
  }

  pctFromTime(t : number) : number {
    return (t - this._axisOrigoMinutes) * 100 / this._axisLengthMinutes;
  }
};

class ResizeOp {
  onComplete : (shiftId : string, beginMinutes : number, endMinutes : number) => void;
  onChange : (beginMinutes : number, endMinutes : number) => void;

  private _shiftId : string;
  private _line : HTMLElement;
  private _xStart : number;
  private _pointerXStart : number;
  private _anchor : number;
  private _timeRangeX : number;
  private _resizingBegin : boolean;

  constructor(shiftId : string, line : HTMLElement, event : PointerEvent) {
    let target = event.target as HTMLElement;
    target.onpointermove = this.drag.bind(this);
    target.onpointerup = this.endDrag.bind(this);
    target.setPointerCapture(event.pointerId);

    this._shiftId = shiftId;
    this._line = line;
    this._resizingBegin = target.classList.contains('left');
    this._pointerXStart = event.screenX;
    this._timeRangeX = this._line.closest('.ps-time-range').getBoundingClientRect().left;

    let lineRect = this._line.getBoundingClientRect();
    this._xStart = (this._resizingBegin ? lineRect.left : lineRect.right) - this._timeRangeX;
    this._anchor = planner.axis.timeFromX(
      (this._resizingBegin ? lineRect.right : lineRect.left) - this._timeRangeX);
  }

  private calculateLine(event : PointerEvent) : {begin : number, end : number} {
    let deltaX = event.screenX - this._pointerXStart;
    let t2 = planner.axis.timeFromX(this._xStart + deltaX);
    if ((t2 - this._anchor) * (this._resizingBegin ? -1 : 1) < 60) {
      t2 = this._anchor + 60 * (this._resizingBegin ? -1 : 1);
    }
    return this._resizingBegin ? {begin: t2, end: this._anchor} :
      {begin: this._anchor, end: t2}
  }

  private drag(event : PointerEvent) {
    let l = this.calculateLine(event);
    if (this.onChange) {
      this.onChange(l.begin, l.end);
    }
  }

  private endDrag(event : PointerEvent) {
    let target = event.target as HTMLElement;
    target.onpointermove = null;
    target.onpointerup = null;
    target.releasePointerCapture(event.pointerId);

    let l = this.calculateLine(event);
    this.onComplete(this._shiftId, l.begin, l.end);
  }
};

class Line {
  private _row : HTMLElement;
  private _line : HTMLElement;
  private _resizeOp : ResizeOp;
  private _length : HTMLElement;
  private _beginMinutes : number;
  private _endMinutes : number;

  constructor(row : HTMLElement) {
    this._row = row;
    this._line = document.createElement('div');
    this._line.className = 'line'
    let label = document.createElement('div');
    label.className = 'role-label';
    let role = document.createElement('span');
    role.className = 'role';
    role.innerText = '*';
    label.appendChild(role);
    this._length = document.createElement('span');
    this._length.className = 'length';
    label.appendChild(this._length);
    this._line.appendChild(label);
    row.querySelector('.ps-time-range').appendChild(this._line);

    label.onclick = this.onClickRoleLabel.bind(this);

    if (Utils.is_touch_enabled()) {
      let adjust = document.createElement('a');
      adjust.setAttribute('href', '');
      adjust.classList.add('adjust-btn');
      let icon = document.createElement('i');
      icon.classList.add('material-icons');
      icon.innerText = 'adjust';
      adjust.appendChild(icon);
      this._line.appendChild(adjust);
      adjust.onclick = this.onClickAdjust.bind(this);
    }
  }

  setTime(beginMinutes : number, endMinutes : number) {
    this._beginMinutes = beginMinutes;
    this._endMinutes = endMinutes;
    planner.positionElement(this._line, beginMinutes, endMinutes);
    this._length.innerText = ((endMinutes - beginMinutes) / 60).toString().replace('.', ',') + 'h';
    if ((endMinutes - beginMinutes) > 600) {
      this._length.classList.add('warning');
    } else {
      this._length.classList.remove('warning');
    }
  }

  setParams(params : {id? : number; beginMinutes? : number; endMinutes? : number;
            role? : number, roleLabel? : string, published? : boolean, employeeId? : string, state? : string}) {
    let employeeSelect = this._row.querySelector('select') as HTMLSelectElement;

    if (params.beginMinutes && params.endMinutes) {
      this.setTime(params.beginMinutes, params.endMinutes);
    }
    if (params.id) {
      if (!this._row.id) {
        this.createResizeHandles();
      }
      this._row.id = `planned_shift_${params.id}`;
      this._row.setAttribute('data-id', params.id.toString());
    }
    if (params.roleLabel) {
      let label = this._line.querySelector('.role-label .role') as HTMLElement;
      label.innerText = params.roleLabel;
    }
    if (params.state) {
      this._row.classList.remove('unassigned', 'draft', 'declined', 'pending', 'accepted', 'outbox');
      this._row.classList.add(params.state);
    }
    if (params.published != null) {
      if (params.published) {
        this._row.classList.add('published');
      } else {
        this._row.classList.remove('published');
      }
      employeeSelect.disabled = params.published;
    }
    this._row.setAttribute('data-employee-id',
                           params.employeeId ? params.employeeId : '');

    if (params.id && params.role != undefined) {
      planner.updateRole(params.id.toString(), params.role);
    }
    if (params.employeeId) {
      employeeSelect.value = params.employeeId;
    }
    M.FormSelect.init(employeeSelect, {classes: 'ps-employee-select'});
  }

  getBoundingClientRect() : {left : number, top : number, width: number, height: number} {
    return this._line.getBoundingClientRect();
  }

  private createResizeHandles() {
    this.createResizeHandle('left');
    this.createResizeHandle('right');
  }

  private createResizeHandle(side : string) {
    let handle = document.createElement('div');
    handle.className = 'resize-handle ' + side;
    handle.onpointerdown = this.resizeBegin.bind(this);
    this._line.append(handle);
  }

  private resizeBegin(event : PointerEvent) {
    this._resizeOp = new ResizeOp(this._row.getAttribute('data-id'), this._line, event);
    this._resizeOp.onChange = this.setTime.bind(this);
    this._resizeOp.onComplete = (shiftId : string, beginMinutes : number, endMinutes : number) => {
      this._resizeOp = null;
      planner.updateShift(shiftId, {start: beginMinutes, end: endMinutes});
    };
  }

  private onClickRoleLabel(event : MouseEvent) {
    planner.showRoleMenu(this._row.getAttribute('data-id'));
  }

  private onClickAdjust(event : MouseEvent) {
    event.preventDefault();
    setShiftDialogTime('form-shift-start', this._beginMinutes);
    setShiftDialogTime('form-shift-end', this._endMinutes);
    M.updateTextFields();

    TimeField.resetAll();
    let dlg = document.getElementById('shift-dialog');
    M.Modal.getInstance(dlg).open();

    document.getElementById('save-shift').onclick = ((event : MouseEvent) => {
      event.preventDefault();
      TimeField.validateAll();
      if (dlg.querySelectorAll('input.invalid').length == 0) {
        M.Modal.getInstance(dlg).close();
        let startMinutes = getShiftDialogTime('form-shift-start');
        let endMinutes = getShiftDialogTime('form-shift-end');
        if (endMinutes < startMinutes) {
          endMinutes = endMinutes + 60 * 24;
        }
        planner.updateShift(this._row.getAttribute('data-id'),
                            {start: startMinutes, end: endMinutes});
      }
    }).bind(this);

  }
};

class DragOp {
  onComplete : (beginMinutes : number, endMinutes : number) => void;

  private _line : HTMLElement;
  private _begin : number;

  constructor(event : PointerEvent) {
    this._begin = planner.axis.timeFromX(event.offsetX);
    let target = event.target as HTMLElement;
    target.onpointermove = this.drag.bind(this);
    target.onpointerup = this.endDrag.bind(this);
    target.setPointerCapture(event.pointerId);

    this._line = document.createElement('div');
    this._line.className = 'line';
    (target.closest('.ps-time-range') as HTMLElement).appendChild(this._line);
    planner.positionElement(this._line, this._begin, this._begin);
  }

  private drag(event : PointerEvent) {
    planner.positionElement(this._line, this._begin, planner.axis.timeFromX(event.offsetX));
  }

  private endDrag(event : PointerEvent) {
    let target = event.target as HTMLElement;
    target.onpointermove = null;
    target.onpointerup = null;
    target.releasePointerCapture(event.pointerId);
    this._line.remove();
    let end = planner.axis.timeFromX(event.offsetX);
    this.onComplete(this._begin < end ? this._begin : end, this._begin < end ? end : this._begin);
  }
};

class AddShiftOverlay {
  private _overlay : HTMLElement;
  private _marker : HTMLElement;
  private _dragOp : DragOp;

  constructor() {
    this._overlay = document.createElement('div');
    this._overlay.id = 'ps-add-shift-overlay';
    if (!Utils.is_touch_enabled()) {
      this._overlay.onmouseover = this.show.bind(this);
      this._overlay.onmouseout = this.hide.bind(this);
      this._overlay.onmousemove = this.follow.bind(this);
      this._overlay.onpointerdown = this.beginDrag.bind(this);
    } else {
      this.setupAddShiftButton();
    }

    this._marker = document.createElement('div');
    this._marker.className = 'ps-marker';
    this._marker.setAttribute('hidden', '');
    document.body.appendChild(this._marker);
  }

  addTo(row : HTMLElement) {
    row.querySelector('.ps-time-range').appendChild(this._overlay);
  }

  remove() {
    this._overlay.remove();
  }

  private setupAddShiftButton() {
    let addButton = document.createElement('a');
    addButton.id = 'ps-add-shift-button';
    addButton.setAttribute('href', '');
    let icon = document.createElement('i');
    icon.classList.add('material-icons');
    icon.innerText = 'add_circle_outline';
    addButton.appendChild(icon);
    this._overlay.appendChild(addButton);

    let dlg = document.getElementById('shift-dialog');
    M.Modal.init(dlg, {dismissable: false});
    TimeField.initAll();

    (dlg.querySelector('.rve-cancel-dlg') as HTMLElement).onclick = (event : MouseEvent) => {
      event.preventDefault();
      M.Modal.getInstance(dlg).close();
    }

    addButton.onclick = (event : MouseEvent) => {
      event.preventDefault();
      (document.getElementById('form-shift-start') as HTMLInputElement).value = '';
      (document.getElementById('form-shift-end') as HTMLInputElement).value = '';
      TimeField.resetAll();
      document.getElementById('save-shift').onclick = ((event : MouseEvent) => {
        event.preventDefault();
        TimeField.validateAll();
        if (dlg.querySelectorAll('input.invalid').length == 0) {
          M.Modal.getInstance(dlg).close();
          this.addShiftFromDialog();
        }
      }).bind(this);

      M.Modal.getInstance(dlg).open();
    }
  }

  private show() {
    this._marker.removeAttribute('hidden');
  }

  private hide() {
    this._marker.setAttribute('hidden', '');
  }

  private beginDrag(event : PointerEvent) {
    this._dragOp = new DragOp(event);
    this._dragOp.onComplete = (beginMinutes : number, endMinutes : number) => {
      this._dragOp = null;
      if ((endMinutes - beginMinutes) >= 60) {
        planner.createShift(beginMinutes, endMinutes);
      }
    }
  }

  private follow(event : MouseEvent) {
    if (!this._dragOp) {
      let totalMinutes = planner.axis.timeFromX(event.offsetX);
      let hours = Math.floor(totalMinutes / 60) % 24;
      let minutes = totalMinutes % 60;
      let snapX = planner.axis.xFromTime(totalMinutes);
      this._marker.style.left = (event.clientX - event.offsetX + snapX) + 'px';
      this._marker.style.top = (event.clientY - event.offsetY) + 'px';
      this._marker.innerText = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    }
  }

  private addShiftFromDialog() {
    let beginMinutes = getShiftDialogTime('form-shift-start');
    let endMinutes = getShiftDialogTime('form-shift-end');
    if (endMinutes < beginMinutes) {
      endMinutes = endMinutes + 24 * 60;
    }
    planner.createShift(beginMinutes, endMinutes);
  }
};

class ShiftsPlanner {
  axis : TimeAxis;

  private _root : Element;
  private _rowTemplate : Element;
  private _addShiftOverlay : AddShiftOverlay;
  private _roleMenu : HTMLElement;
  private _lines : {[id: number]: Line};
  private _journalBox : HTMLTextAreaElement;
  private _journalAutoSaveTimerId;

  constructor(root : Element) {
    this._root = root;
    this._rowTemplate = root.querySelector('.ps-row.template');
    this._rowTemplate.classList.remove('template');
    this._rowTemplate.remove();
    this._roleMenu = document.getElementById('ps-role-menu') as HTMLElement;
    this.axis = new TimeAxis(6 * 60, (24 - 1) * 60, root.querySelector('.head .ps-time-range'));
    this._addShiftOverlay = new AddShiftOverlay();
    this._lines = {};
    this.addEmptyShiftRow();
    document.onpointerdown = this.handleRoleMenu.bind(this);

    this._journalBox = root.querySelector('.ps-journal textarea') as HTMLTextAreaElement;
    this._journalBox.oninput = this.onJournalInput.bind(this)
    this._journalBox.onchange = this.onJournalChange.bind(this)

    let rcb = document.getElementById('day-ready-checkbox');
    if (rcb) {
      rcb.onchange = this.saveJournalReady.bind(this);
    }
    let acb = document.getElementById('day-approved-checkbox');
    if (acb) {
      acb.onchange = this.saveJournalReady.bind(this);
    }
  }

  loadShifts() {
    fetch(this._root.getAttribute('data-load-shifts-url'), {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
    })
    .then(response => response.json())
    .then(data => {
      data.forEach(d => this.addShift(d));
    })
    .catch((error) => {
      Flash.failure('Ett tekniskt fel inträffade');
      console.error('Error:', error);
    });
  }

  addShift(params : {id? : number; beginMinutes : number; endMinutes : number,
           employeeId?: string, published?: boolean, state? : string, role? : number}) : Line {
    let currentRow = document.getElementById('ps-add-shift-overlay').closest('.ps-row') as HTMLElement;
    let line = new Line(currentRow);
    if (params.id != null) {
      this._lines[params.id] = line;
    }
    line.setParams(params);
    this.addEmptyShiftRow();
    return line;
  }

  createShift(beginMinutes : number, endMinutes : number) {
    let line = this.addShift({beginMinutes: beginMinutes, endMinutes: endMinutes});
    let data = {start: beginMinutes, end: endMinutes};
    fetch(this._root.getAttribute('data-create-shift-url'), {
      method: 'POST',
      headers: Csrf.add_header({'Content-Type': 'application/json'}),
      body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(data => {
      this._lines[data.id] = line;
      line.setParams({id: data.id});
      this.showRoleMenu(data.id);
    })
    .catch((error) => {
      Flash.failure('Ett tekniskt fel inträffade');
      console.error('Error:', error);
    });
  }

  positionElement(e : HTMLElement, beginMinutes : number, endMinutes : number) {
    let left = this.axis.pctFromTime(beginMinutes < endMinutes ? beginMinutes : endMinutes);
    let right = this.axis.pctFromTime(beginMinutes < endMinutes ? endMinutes : beginMinutes);
    e.style.left = `${left}%`;
    e.style.width = `${right - left}%`;
  }

  updateShift(id : string, params : {start? : number, end? : number,
              employee_id? : string, published? : boolean, role? : number}) {

    if (params.role != undefined) {
      this.updateRole(id, params.role);
    }

    fetch(this._root.getAttribute('data-shift-url').replace('NNN', id), {
      method: 'PUT',
      headers: Csrf.add_header({'Content-Type': 'application/json'}),
      body: JSON.stringify({planned_shift: params}),
    })
    .then(response => response.json())
    .then(data => {
      this._lines[id].setParams(data);
    })
    .catch((error) => {
      Flash.failure('Ett tekniskt fel inträffade');
      console.error('Error:', error);
    });
  }

  private addEmptyShiftRow() {
    this._addShiftOverlay.remove();
    let newRow = this._rowTemplate.cloneNode(true) as HTMLElement;
    this._root.querySelector('.ps-shifts').appendChild(newRow);
    this._addShiftOverlay.addTo(newRow);
    let employeeSelect = newRow.querySelector('select') as HTMLElement;
    M.FormSelect.init(employeeSelect, {classes: 'ps-employee-select'});
    employeeSelect.onchange = this.selectEmployee.bind(this);
    (newRow.querySelector('.action-delete') as HTMLElement).onclick = this.deleteRow.bind(this);
    (newRow.querySelector('.action-publish') as HTMLElement).onclick = this.publishRow.bind(this);
    (newRow.querySelector('.action-unpublish') as HTMLElement).onclick = this.unpublishRow.bind(this);
  }

  private deleteRow(event : MouseEvent) {
    event.preventDefault();
    let row = (event.target as HTMLElement).closest('.ps-row');
    let shiftId = row.getAttribute('data-id');
    fetch(this._root.getAttribute('data-shift-url').replace('NNN', shiftId), {
      method: 'DELETE',
      headers: Csrf.add_header({'Content-Type': 'application/json'}),
    })
    .then(response => {
      if (response.ok) {
        row.remove();
      }
    })
    .catch((error) => {
      Flash.failure('Ett tekniskt fel inträffade');
      console.error('Error:', error);
    });
  }

  private unpublishRow(event : MouseEvent) {
    this.changePublishedState(event, false);
  }

  private publishRow(event : MouseEvent) {
    this.changePublishedState(event, true);
  }

  private changePublishedState(event : MouseEvent, published : boolean) {
    event.preventDefault();
    let row = (event.target as HTMLElement).closest('.ps-row');
    let shiftId = row.getAttribute('data-id');
    this.updateShift(shiftId, {published: published});
  }

  private selectEmployee(event : MouseEvent) {
    let select = (event.target as HTMLSelectElement);
    let row = select.closest('.ps-row');
    let shiftId = row.getAttribute('data-id');
    this.updateShift(shiftId, {employee_id: select.value});
  }

  showRoleMenu(shift_id : string) {
    let lineRc = this._lines[shift_id].getBoundingClientRect();
    this._roleMenu.setAttribute('data-planned-shift-id', shift_id.toString());
    let r = (this._roleMenu.parentNode as HTMLElement).getBoundingClientRect();
    this._roleMenu.style.left = (lineRc.left - r.left) + 'px';
    this._roleMenu.style.top = (lineRc.top - r.top) + 'px';
    this._roleMenu.removeAttribute('hidden');
  }

  private handleRoleMenu(event : PointerEvent) {
    this._roleMenu.setAttribute('hidden', '');
    let roleId = (event.target as Element).getAttribute('data-role-id');
    if (roleId) {
      let shiftId = this._roleMenu.getAttribute('data-planned-shift-id');
      this.updateShift(shiftId, {role: parseInt(roleId)});
    }
  }

  private onJournalInput(event : InputEvent) {
    if (this._journalAutoSaveTimerId) {
      clearTimeout(this._journalAutoSaveTimerId);
    }
    this._journalAutoSaveTimerId = setTimeout(this.saveJournal.bind(this), 1000);
  }

  private onJournalChange(event : Event) {
    this.saveJournal();
  }

  private saveJournal() {
    if (this._journalAutoSaveTimerId) {
      clearTimeout(this._journalAutoSaveTimerId);
      this._journalAutoSaveTimerId = null;
    }
    this._journalBox.classList.add('saving');
    this._journalBox.classList.remove('error');
    fetch(this._root.getAttribute('data-save-journal-url'), {
      method: 'PUT',
      headers: Csrf.add_header({'Content-Type': 'application/json'}),
      body: JSON.stringify({comment: this._journalBox.value}),
    })
    .then(response => {
      this._journalBox.classList.remove('saving');
      if (!response.ok) {
        this._journalBox.classList.add('error');
      }
    })
    .catch((error) => {
      this._journalBox.classList.remove('saving');
      this._journalBox.classList.add('error');
      Flash.failure('Ett tekniskt fel inträffade');
      console.error('Error:', error);
    });
  }

  private saveJournalReady(event : Event) {
    let ready = document.getElementById('day-ready-checkbox') as HTMLInputElement
    let approved = document.getElementById('day-approved-checkbox') as HTMLInputElement

    if (!ready.checked) {
      approved.checked = false;
    }

    fetch(this._root.getAttribute('data-save-journal-url'), {
      method: 'PUT',
      headers: Csrf.add_header({'Content-Type': 'application/json'}),
      body: JSON.stringify({ready: ready.checked, approved: approved.checked}),
    })
    .catch((error) => {
      Flash.failure('Ett tekniskt fel inträffade');
      console.error('Error:', error);
    });
  }

  public updateRole(shift_id : string, role : number) {
    let row = document.getElementById('planned_shift_' + shift_id);
    row.setAttribute('data-role', role.toString());
    row.querySelectorAll('.ps-title option').forEach((e) => {
      let option = e as HTMLOptionElement;
      let label = option.getAttribute('data-name');
      let gradeAttrib = option.getAttribute('data-grade-role-' + role);
      if (gradeAttrib) {
        let grade = parseInt(gradeAttrib);
        if (grade > 0) {
          label = label + ' ';
          for (let i=0; i<grade; ++i) {
            label = label + '★';
          }
        }
      }
      option.innerText = label;
    });
  }
};

document.addEventListener('turbo:load', () => {
  let e = document.getElementById('shifts-planner');
  if (e != null && e.querySelector('.ps-row.template') != null) {
    planner = new ShiftsPlanner(e);
    planner.loadShifts();
  }

  let daySelect = document.getElementById('day-select');
  if (daySelect != null) {
    let picker = new Litepicker({
      element: daySelect,
      showWeekNumbers: true,
      scrollToDate: true,
      startDate: daySelect.getAttribute('data-date')
    });
    (picker as any).on('hide', () => {
      let date = picker.getDate().format('YYYY-MM-DD');
      let url = daySelect.getAttribute('data-url').replace('NNN', date);
      if (date != daySelect.getAttribute('data-date')) {
        document.location.assign(url);
      }
    });
  }

  let duplicate = document.querySelector('#duplicate-shifts') as HTMLElement;

  if (duplicate != null) {
    let picker = new Litepicker({
      element: duplicate,
      showWeekNumbers: true,
      plugins: ['multiselect']
    });
    (picker as any).on('before:show', () => {
      (picker as any).clearMultipleDates();
    });
    (picker as any).on('button:apply', () => {
      let dates = (picker as any).getMultipleDates().map(x => picker.DateTime(x).format('YYYY-MM-DD'));
      if (dates.length > 0) {
        fetch(duplicate.getAttribute('data-url'), {
          method: 'POST',
          headers: Csrf.add_header({'Content-Type': 'application/json'}),
          body: JSON.stringify({to_dates: dates}),
        })
        .then(response => response.json())
        .then(data => {
          Flash.success('Passen kopierades till ' + data.copies.toString() +
                        (data.copies > 1 ? ' andra dagar' : ' annan dag'));
        })
        .catch((error) => {
          Flash.failure('Ett tekniskt fel inträffade');
          console.error('Error:', error);
        });
      }
    });
  }
});
