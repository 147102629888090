import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="site-nav-search"
export default class extends Controller {
  static targets = ['input']

  open () {
    this.element.dataset.expanded = true
    this.inputTarget.focus()
  }

  close () {
    if (!this.inputTarget.value) {
      delete this.element.dataset.expanded
    }
  }
}
