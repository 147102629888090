import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['calendar', 'sections', 'previousMonth', 'nextMonth']

  initialize () {
    this.isMobileMode = undefined
    this.currentMonthURL = undefined
    this.isOpen = false
    this.isUpdatingCurrentMonths = false
    this.isUpdatingPreviousMonths = false
    this.isUpdatingNextMonths = false
  }

  calendarTargetConnected () {
    this.isOpen = true
    this.isMobileMode = this.isScreenMobile()

    if (this.isMobileMode) this.addMonths()
  }

  calendarTargetDisconnected () {
    this.isOpen = false
  }

  onScroll (event) {
    if (event.currentTarget.scrollTop <= 1) {
      this.addPreviousMonthsAndScroll()
    } else if (event.currentTarget.scrollTop >= event.currentTarget.scrollHeight - event.currentTarget.clientHeight - 1) {
      this.addNextMonths()
    }
  }

  resize () {
    const isPreviousMobileMode = this.isMobileMode

    if (!this.isOpen) return

    if (this.isScreenMobile()) {
      this.setMobileMode(isPreviousMobileMode)
    } else {
      this.setDesktopMode(isPreviousMobileMode)
    }
  }

  setMobileMode (isPreviousMobileMode) {
    this.isMobileMode = true

    if (isPreviousMobileMode !== this.isMobileMode) {
      this.addMonths()
    }
  }

  setDesktopMode (isPreviousMobileMode) {
    this.isMobileMode = false

    if (isPreviousMobileMode !== this.isMobileMode) {
      this.setCurrentMonths()
    }
  }

  addMonths () {
    this.addNextMonths()
    this.addPreviousMonthsAndScroll()
  }

  async addPreviousMonthsAndScroll () {
    const currentElement = this.sectionsTarget.firstElementChild
    await this.addPreviousMonths()
    this.calendarTarget.scrollTo(0, currentElement.offsetTop)
  }

  async addPreviousMonths () {
    if (this.isUpdatingPreviousMonths || !this.isMobileMode) return

    this.isUpdatingPreviousMonths = true

    const previousMonthsHTML = await this.getMonths(this.previousMonthTarget.href)

    const allMonths = previousMonthsHTML.querySelectorAll('[data-date-picker-mobile-section]')
    const firstMonth = allMonths[0]
    this.sectionsTarget.prepend(firstMonth)

    const newPreviousLink = previousMonthsHTML.querySelector('[data-date-picker-mobile-target="previousMonth"]')
    this.previousMonthTarget.replaceWith(newPreviousLink)
    this.currentMonthURL = previousMonthsHTML.querySelector('[data-date-picker-mobile-target="nextMonth"]').href

    this.dispatch('updateCalendarDisplay')
    this.isUpdatingPreviousMonths = false
  }

  async addNextMonths () {
    if (this.isUpdatingNextMonths || !this.isMobileMode) return

    this.isUpdatingNextMonths = true

    const nextMonthsHTML = await this.getMonths(this.nextMonthTarget.href)

    const allMonths = nextMonthsHTML.querySelectorAll('[data-date-picker-mobile-section]')
    const lastMonth = allMonths[allMonths.length - 1]
    this.sectionsTarget.append(lastMonth)

    const newNextLink = nextMonthsHTML.querySelector('[data-date-picker-mobile-target="nextMonth"]')
    this.nextMonthTarget.replaceWith(newNextLink)

    this.dispatch('updateCalendarDisplay')
    this.isUpdatingNextMonths = false
  }

  async setCurrentMonths () {
    if (this.isUpdatingCurrentMonths || this.isMobileMode) return

    this.isUpdatingCurrentMonths = true

    const currentMonthsHTML = await this.getMonths(this.currentMonthURL)
    const calendar = currentMonthsHTML.querySelector('[data-date-picker-mobile-target="calendar"]')
    this.calendarTarget.replaceWith(calendar)

    this.dispatch('updateCalendarDisplay')
    this.isUpdatingCurrentMonths = false
  }

  async getMonths (url) {
    const response = await fetch(url)
    const monthsText = await response.text()
    const monthsHTML = new DOMParser().parseFromString(monthsText, 'text/html')

    return monthsHTML
  }

  isScreenMobile () {
    return window.innerWidth < 640
  }
}
