import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="table-row-expander"
export default class extends Controller {
  toggle (event) {
    this.element.querySelectorAll("[data-row-id='" + event.params.rowId + "']").forEach((e) => {
      if (e.dataset.expanded) {
        delete e.dataset.expanded
      } else {
        e.dataset.expanded = true
      }
    })

    this.dispatch('toggle')
  }
}
