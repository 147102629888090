import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="auto-refresh-frame"
export default class extends Controller {
  static values = {
    src: String
  }

  connect () {
    setTimeout(() => {
      this.element.closest('turbo-frame').src = this.srcValue
    }, 1000)
  }
}
