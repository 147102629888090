import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'

// Connects to data-controller="dropdown-menu-instance"
export default class extends Controller {
  connect () {
    this.fullyOpened = false
    setTimeout(() => {
      enter(this.element)
      this.fullyOpened = true
    }, 0)
  }

  async hide (event) {
    if (!this.fullyOpened) {
      return
    }
    await leave(this.element)
    this.element.remove()
    this.element.dispatchEvent(new Event('closed.omnis'))
  }
}
