import { Controller } from '@hotwired/stimulus'
import { computePosition, autoUpdate, flip, shift, limitShift, hide } from '@floating-ui/dom'

export default class extends Controller {
  static targets = ['menuTemplate', 'icon']

  show () {
    if (this.menuElement) {
      return
    }

    this.menuElement = this.menuTemplateTarget.content.firstElementChild.cloneNode(true)
    this.element.appendChild(this.menuElement)
    const iconElement = this.iconTarget.querySelector('i') || this.iconTarget.querySelector('span')

    iconElement.classList.remove('i-angle-down')
    iconElement.classList.add('i-angle-up')

    const cleanup = autoUpdate(this.element, this.menuElement, () => {
      computePosition(this.element, this.menuElement, {
        placement: 'bottom',
        middleware: [flip(), shift({ limiter: limitShift() }), hide()]
      }).then(({ x, y, middlewareData }) => {
        Object.assign(this.menuElement.style, {
          position: 'absolute',
          left: `${x}px`,
          top: `${y}px`,
          visibility: middlewareData.hide.referenceHidden ? 'hidden' : 'visible'
        })
      })
    })

    this.menuElement.addEventListener('closed.omnis', (e) => {
      if (this.menuElement) {
        this.menuElement.remove()
      }
      cleanup()
      iconElement.classList.remove('i-angle-up')
      iconElement.classList.add('i-angle-down')
      this.menuElement = null
    })
  }
}
