export default class Flash {
  private static message(type : string, msg : string) {
    let msgDiv = document.createElement('div');
    msgDiv.classList.add('message');
    msgDiv.classList.add('message-' + type);
    let icon = document.createElement('i');
    icon.classList.add('material-icons');
    msgDiv.appendChild(icon);
    let span = document.createElement('span');
    span.innerText = msg;
    msgDiv.appendChild(span);

    let messageArea = document.querySelector('.rve-messages') as HTMLElement;
    messageArea.appendChild(msgDiv);
  }

  public static success(msg : string) { this.message('success', msg); }
  public static failure(msg : string) { this.message('failure', msg); }
}
