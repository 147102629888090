import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="temporary-children"
export default class extends Controller {
  removeTemporaryChildren () {
    document.querySelectorAll('[data-temporary-children]').forEach((e) => {
      e.replaceChildren()
    })
  }
}
