import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="time-of-day-edit"
export default class extends Controller {
  connect () {
    this.boundChangeHandler = this.onChangeHandler.bind(this)
    this.element.addEventListener('change', this.boundChangeHandler)
  }

  disconnect () {
    this.element.removeEventListener('change', this.boundChangeHandler)
  }

  onChangeHandler (event) {
    const zeroPrefix = function (s) {
      if (s.length === 1) {
        return '0' + s
      }
      return s
    }

    const v = this.element.value
    let m = v.match(/^\s*(\d\d?)\s*$/)
    if (m) {
      this.element.value = zeroPrefix(m[1]) + ':00'
      return
    }

    m = v.match(/^\s*(\d{4})\s*$/)
    if (m) {
      this.element.value = m[1].substr(0, 2) + ':' + m[1].substr(2, 2)
      return
    }

    m = v.match(/^\s*(\d+)[.:-](\d+)\s*$/)
    if (m) {
      this.element.value = zeroPrefix(m[1]) + ':' + zeroPrefix(m[2])
    }
  }
}
