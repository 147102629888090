import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'

// Connects to data-controller='offer'
export default class extends Controller {
  static targets = ['preview', 'productMenu']

  connect () {
  }

  reloadPreview () {
    this.previewTarget.contentWindow.location.reload()
  }

  openProductMenu (event) {
    enter(this.productMenuTarget)
  }

  closeProductMenu (event) {
    leave(this.productMenuTarget)
  }
}
