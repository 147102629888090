import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="expense-category-menu"
export default class extends Controller {
  static targets = ['menu']

  insertAfter (newNode, existingNode) {
    existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling)
  }

  show (event) {
    const inputElement = event.target
    const currentElementIndex = inputElement.id
    const template = document.getElementById('expense-category-menu')
    const clone = template.content.cloneNode(true)
    const ulElement = clone.querySelector('ul')
    const liElements = ulElement.querySelectorAll('li')

    this.insertAfter(ulElement, inputElement)

    ulElement.classList.add('booking-account-' + event.target.id)

    liElements.forEach((item, _index) => {
      item.setAttribute('data-row-index', currentElementIndex)
    })
    ulElement.classList.remove('hide')
  }

  hide (event) {
    event.target.nextElementSibling.remove()
  }
}
