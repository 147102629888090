import { Controller } from '@hotwired/stimulus'
import { post, patch } from '@rails/request.js'
import Flash from '../src/flash'

// Connects to data-controller="tickable-task-list"
export default class extends Controller {
  static targets = ['completedList', 'incompleteList']
  static classes = ['empty']
  static values = {
    errorMessage: String
  }

  async tickTask (event) {
    this.#handleTask(event, this.completedListTarget)
  }

  async untickTask (event) {
    this.#handleTask(event, this.incompleteListTarget)
  }

  async #handleTask (event, targetList) {
    event.preventDefault()

    const url = event.currentTarget.getAttribute('href')
    const row = event.currentTarget.closest('li')
    const sourceList = event.currentTarget.closest('ul')
    const method = event.currentTarget.getAttribute('data-req-method')

    let response
    if (method === 'patch') {
      response = await patch(url)
    } else {
      response = await post(url)
    }

    if (response.ok) {
      targetList.prepend(row)
      this.#updateEmptyState(sourceList)
      this.#updateEmptyState(targetList)
    } else {
      Flash.failure(this.errorMessageValue)
    }
  }

  #updateEmptyState (list) {
    if (list.querySelectorAll('li').length === 0) {
      list.classList.add(...this.emptyClasses)
    } else {
      list.classList.remove(...this.emptyClasses)
    }
  }
}
