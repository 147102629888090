import { Controller } from '@hotwired/stimulus'

// In this controller we are going to use two DOM elements:
// - the current element, the one where the controller is set, which will be used to determine
// if we want to restore the scroll or not, depending if the scrollHeight has changed or not
// - the scrollableElement, which is the element that will have the value of the scroll (scrollTop)
export default class extends Controller {
  // "localStorageKey" value allows to override the default local storage key
  // it can be useful for example when having multiple auto-scrolls on the same page
  static values = { localStorageKey: String }

  connect () {
    // we don't activate the scrolling if the current element is not visible
    // https://stackoverflow.com/questions/19669786/check-if-element-is-visible-in-dom
    if (!this.element.offsetParent) return

    this.scrollableElement = this.getScrollableElement(this.element)

    if (!this.scrollableElement) return

    this.localStoragekey = `auto-scroll-${this.localStorageKeyValue || window.location.pathname}`
    this.scrollableElementScrollHeight = this.scrollableElement.scrollHeight
    this.restoreScroll()
    this.boundRecordScroll = this.recordScroll.bind(this)
    this.scrollableElement.addEventListener('scroll', this.boundRecordScroll)
  }

  disconnect () {
    if (!this.scrollableElement) return

    this.scrollableElement.removeEventListener('scroll', this.boundRecordScroll)
    this.saveScroll()
  }

  // we use the current URL as the key, and we store:
  // - the scrollTop value
  // - the date (so that we can decide not to restore the scroll when the data was saved longer than some time)
  // - the scrollHeight (so that we can decide not to restore the scroll if the scrollHeight has changed)
  saveScroll () {
    localStorage.setItem(
      this.localStoragekey,
      JSON.stringify({
        scrollTop: this.scrollableElementScrollTop,
        date: new Date(),
        scrollHeight: this.scrollableElementScrollHeight
      })
    )
  }

  // we restore the data only if the height has not changed more than 100px, and if the scroll
  // data were saved less than 5 minutes ago
  restoreScroll () {
    const scrollData = JSON.parse(localStorage.getItem(this.localStoragekey))

    if (!scrollData) return

    const scrollHeightWithinRange = Math.abs(this.scrollableElementScrollHeight - scrollData.scrollHeight) < 100

    const savedRecently = new Date() - new Date(scrollData.date) < 5 * 60 * 1000

    if (scrollHeightWithinRange && savedRecently) {
      this.scrollableElement.scrollTop = scrollData.scrollTop
    }
  }

  // we need to record the scrollTop value in real time, because when the element will disconnect
  // the scrollTop value will be 0 (the element will already be disconnected)
  recordScroll (event) {
    this.scrollableElementScrollTop = event.target.scrollTop
  }

  // we check if it is the current element which is scrollable, otherwise we loop over the parents
  // to find the scrollable element
  getScrollableElement (element) {
    if (!element) {
      return
    }
    if (this.isScrollable(element)) {
      return element
    }

    return this.getScrollableElement(element.parentElement)
  }

  // from https://phuoc.ng/collection/html-dom/check-if-an-element-is-scrollable/
  isScrollable (element) {
    // Compare the height to see if the element has scrollable content
    const hasScrollableContent = element.scrollHeight > element.clientHeight

    // It's not enough because the element's `overflow-y` style can be set as hidden
    const isOverflowHidden = window.getComputedStyle(element).overflowY === 'hidden'

    return hasScrollableContent && !isOverflowHidden
  };
}
