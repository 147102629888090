import M from 'materialize-css/dist/js/materialize'
import { Litepicker } from 'litepicker'
import Flash from './flash.ts'

class ExpenseTool {
  private _root : Element;

  constructor(root : Element) {
    this._root = root;
    M.FormSelect.init(root.querySelectorAll('select'), {});
    root.querySelectorAll('.js-single-date-field').forEach(e => {
      let picker = new Litepicker({element: e as HTMLElement,
        zIndex: 99999, singleMode: true,
        showWeekNumbers: true
      });
      (picker as any).on('hide', function () { M.updateTextFields(); });
    });
  }
};

document.addEventListener('turbo:load', () => {
  let e = document.getElementById('expense-tool');
  if (e != null) {
    new ExpenseTool(e);
  }
});
