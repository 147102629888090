import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="dynamic-fields"
export default class extends Controller {
  static targets = ['template']

  add (event) {
    event.preventDefault()
    this.templateTarget.insertAdjacentHTML('beforebegin',
      this.templateTarget.innerHTML.replace(/__CHILD_INDEX__/g, new Date().getTime().toString())
    )
  }
}
