import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['entity', 'bullet', 'previousButton', 'nextButton']

  connect () {
    this.updateButtons()
  }

  bulletClick (event) {
    const indexClicked = event.params.index
    this.updateSlider(indexClicked)
    this.updateButtons()
  }

  previous (_event) {
    const indexDisplayed = this.getIndexDisplayed()
    const newIndex = indexDisplayed > 0 ? indexDisplayed - 1 : this.entityTargets.length - 1
    this.updateSlider(newIndex)
    this.updateButtons()
  }

  next (_event) {
    const indexDisplayed = this.getIndexDisplayed()
    const newIndex = indexDisplayed < this.entityTargets.length - 1 ? indexDisplayed + 1 : 0
    this.updateSlider(newIndex)
    this.updateButtons()
  }

  updateSlider (index) {
    this.selectElement(this.entityTargets, index)
    this.selectElementBullet(this.bulletTargets, index)
  }

  getIndexDisplayed () {
    for (let i = 0; i < this.entityTargets.length; i++) {
      if (!this.entityTargets[i].classList.contains('hidden')) return i
    }
  }

  updateButtons () {
    const indexDisplayed = this.getIndexDisplayed()
    const firstPage = indexDisplayed === 0
    const lastPage = indexDisplayed + 1 === this.entityTargets.length
    const onePage = this.entityTargets.length === 1

    if (onePage) {
      this.previousButtonTarget.disabled = true
      this.nextButtonTarget.disabled = true
    } else if (firstPage) {
      this.previousButtonTarget.disabled = true
      this.nextButtonTarget.disabled = false
    } else if (lastPage) {
      this.previousButtonTarget.disabled = false
      this.nextButtonTarget.disabled = true
    } else {
      this.previousButtonTarget.disabled = false
      this.nextButtonTarget.disabled = false
    }
  }

  selectElement (collection, index) {
    for (let i = 0; i < collection.length; i++) {
      const element = collection[i]
      if (i === index) {
        element.classList.remove('hidden')
      } else {
        element.classList.add('hidden')
      }
    }
  }

  selectElementBullet (collection, index) {
    for (let i = 0; i < collection.length; i++) {
      const element = collection[i]
      if (i === index) {
        element.dataset.selected = ''
      } else {
        delete element.dataset.selected
      }
    }
  }
}
