import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['sourceContainer']
  longTouchDelay = 500 // Duration for long touch (in ms)
  isDragging = false // Tracks whether dragging is occurring
  dragStarted = false // Tracks whether the drag has actually started

  connect () {
    this.boundMouseUp = this.mouseup.bind(this)
    this.boundMouseMove = this.mousemove.bind(this)
    window.addEventListener('mouseup', this.boundMouseUp)
    window.addEventListener('mousemove', this.boundMouseMove)
  }

  disconnect () {
    window.removeEventListener('mouseup', this.boundMouseUp)
    window.removeEventListener('mousemove', this.boundMouseMove)
  }

  drag (event) {
    event.preventDefault()

    this.elementClicked = event.target
    this.sourceElement = event.currentTarget

    this.cloneElement(this.sourceElement)
  }

  touchstart (event) {
    this.touchTarget = event.target
    this.touchCurrentTarget = event.currentTarget
    this.dragStarted = false // Reset drag state

    // Start long touch timer
    this.longTouchTimer = setTimeout(() => {
      this.onLongTouch(this.touchTarget, this.touchCurrentTarget)
    }, this.longTouchDelay)

    // Set up a move listener to prevent scroll only if dragging starts
    this.preventScrollOnMove()
  }

  // Handle touchend event
  touchend (event) {
    clearTimeout(this.longTouchTimer) // Clear long touch timer

    if (this.elementClone) {
      this.elementClone.remove()
    }

    if (this.dragStarted) {
      const element = document.elementFromPoint(event.changedTouches[0].clientX, event.changedTouches[0].clientY)
      if (element) this.drop(element)
    }

    // Reset flags and allow scrolling again
    this.isDragging = false
    this.dragStarted = false
    this.removePreventScroll()
  }

  // Handle touchmove event
  touchmove (event) {
    // Only prevent scrolling if dragging has started
    if (this.isDragging) {
      event.preventDefault() // Prevent scrolling during drag

      if (!this.elementClone) return

      this.elementClone.style.top = event.changedTouches[0].clientY - (this.elementClone.offsetHeight / 2) + 'px'
      this.elementClone.style.left = event.changedTouches[0].clientX - (this.elementClone.offsetWidth / 2) + 'px'
    }
  }

  // Triggered after a long touch
  onLongTouch (target, currentTarget) {
    this.isDragging = true // Dragging is active
    this.dragStarted = true // Dragging has started

    // Perform long touch-specific action
    this.elementClicked = target
    this.sourceElement = currentTarget

    this.cloneElement(this.sourceElement)
  }

  mouseup (event) {
    if (!this.sourceElement) return

    this.elementClone.remove()
    const element = document.elementFromPoint(event.clientX, event.clientY)

    if (element) this.drop(element)
  }

  mousemove (event) {
    if (!this.elementClone) return

    this.elementClone.style.top = event.clientY - (this.elementClone.offsetHeight / 2) + 'px'
    this.elementClone.style.left = event.clientX - (this.elementClone.offsetWidth / 2) + 'px'
  }

  cloneElement (element) {
    this.elementClone = element.cloneNode(true)
    this.elementClone.style.position = 'fixed'
    this.elementClone.style.zIndex = 1000
    this.elementClone.style.width = element.offsetWidth + 'px'
    this.elementClone.style.height = element.offsetHeight + 'px'
    this.elementClone.style.top = element.getBoundingClientRect().top + 'px'
    this.elementClone.style.left = element.getBoundingClientRect().left + 'px'

    // Set pointer-events to none to avoid interference
    this.elementClone.style.pointerEvents = 'none'

    element.appendChild(this.elementClone)
  }

  drop (element) {
    const targetContainer = element.closest('[data-parent-drag-drop-target="sourceContainer"]')
    const sourceContainer = this.sourceContainerTarget

    if (targetContainer !== sourceContainer) {
      this.sourceElement.querySelector('input[type="submit"]').click()
    } else {
      this.sourceElement = undefined
      this.elementClicked.click()
    }
  }

  preventScrollOnMove () {
    window.addEventListener('touchmove', this.preventScroll, { passive: false })
  }

  removePreventScroll () {
    window.removeEventListener('touchmove', this.preventScroll, { passive: false })
  }

  // Function to prevent scrolling if dragging
  preventScroll (event) {
    if (this.isDragging) {
      event.preventDefault() // Disable scrolling while dragging
    }
  }
}
