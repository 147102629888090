import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="filter"
export default class extends Controller {
  static targets = ['table', 'freetext']

  connect () {
    this.update()
  }

  setFilter (event) {
    this.dataFilter = event.params
    this.update()
  }

  update () {
    this.tableTarget.querySelectorAll('tbody tr').forEach((row) => {
      if (this.#rowMatch(row)) {
        row.classList.remove('hidden')
      } else {
        row.classList.add('hidden')
      }
    })
  }

  #rowMatch (row) {
    if (this.dataFilter && !Object.entries(this.dataFilter).every(([key, value]) =>
      row.dataset[key] === value.toString())) {
      return false
    }
    const filterText = this.freetextTarget.value.toLowerCase()
    return row.innerText.toLowerCase().indexOf(filterText) !== -1
  }
}
