import { Controller } from '@hotwired/stimulus'
import { computePosition, offset, shift } from '@floating-ui/dom'

export default class extends Controller {
  static targets = ['submenu']

  connect () {
    this.insideItem = false
    this.insideSubmenu = false
  }

  toggle () {
    if (this.element.dataset.expanded) {
      delete this.element.dataset.expanded
    } else {
      this.element.dataset.expanded = true
    }
    this.reevaluateState()
  }

  enterItem (event) {
    if (this.element.contains(event.target)) {
      this.insideItem = true
      this.reevaluateState()
    }
  }

  leaveItem (event) {
    if (!this.element.contains(event.relatedTarget)) {
      this.insideItem = false
      this.reevaluateState()
    }
  }

  enterSubmenu (event) {
    if (this.submenu.contains(event.target)) {
      this.insideSubmenu = true
      this.reevaluateState()
    }
  }

  leaveSubmenu (event) {
    if (!this.submenu.contains(event.relatedTarget)) {
      this.insideSubmenu = false
      this.reevaluateState()
    }
  }

  reevaluateState () {
    if (this.revaluateTimerId) {
      clearTimeout(this.revaluateTimerId)
    }
    this.revaluateTimerId = setTimeout(() => {
      const shouldBeVisible = !this.element.dataset.expanded && (this.insideItem || this.insideSubmenu)
      if (shouldBeVisible && !this.submenu) {
        this.showFloating()
      } else if (!shouldBeVisible && this.submenu) {
        this.hideFloating()
      }
    }, 10)
  }

  showFloating () {
    this.submenu = this.submenuTarget.cloneNode(true)
    this.submenu.dataset.floating = true

    document.body.appendChild(this.submenu)

    const submenu = this.submenu

    computePosition(this.element, submenu, {
      placement: 'right-start',
      middleware: [offset(-20, 0), shift({ padding: 6 })]
    }).then(({ x, y, placement, middlewareData }) => {
      Object.assign(submenu.style, { left: `${x}px`, top: `${y}px` })
    })

    this.submenu.addEventListener('mouseenter', (event) => { this.enterSubmenu(event) })
    this.submenu.addEventListener('mouseleave', (event) => { this.leaveSubmenu(event) })
  }

  hideFloating (event) {
    this.submenu.remove()
    delete this.submenu
  }
}
