import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  // the optional inputTarget allows to attach the Numpad to an input by default
  static targets = ['input']

  connect () {
    if (this.hasInputTarget) this.inputElement = this.inputTarget
  }

  selectInput (event) {
    this.inputElement = event.currentTarget
  }

  clickNumber (event) {
    event.preventDefault()
    this.#focusBlur(() => {
      this.inputElement.value = `${this.inputElement.value}${event.currentTarget.dataset.value}`
    })
  }

  clickBackspace (event) {
    event.preventDefault()
    this.#focusBlur(() => {
      this.inputElement.value = this.inputElement.value.substring(
        0, this.inputElement.value.length - 1
      )
    })
  }

  // this function allows to trigger a blur event on the input each time we click on
  // the numpad, so that we can attach a listener and do some actions (like transform the data)
  #focusBlur (method) {
    if (!this.inputElement) return

    this.inputElement.focus()
    method()
    this.inputElement.blur()
  }
}
