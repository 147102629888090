import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="checklist-add-item"
export default class extends Controller {
  static targets = ['subchecklistSelect', 'items']

  connect () {
  }

  add (event) {
    event.preventDefault()
    const selectedId = this.subchecklistSelectTarget.value
    if (selectedId) {
      const template = document.getElementById('subchecklist-template-' + selectedId)
      this.itemsTarget.insertAdjacentHTML('beforeend',
        template.innerHTML.replace(/__CHILD_INDEX__/g, new Date().getTime().toString()))
    }
  }
}
