// check_all_controller

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['inputText', 'inputCheckbox', 'allCheckbox', 'cheboxRow']

  connect () {
    if (this.hasAllCheckboxTarget && this.allCheckboxTarget.getAttribute('data-partial-checked') === 'true') {
      this.allCheckboxTarget.indeterminate = true
    }
  }

  updateAll (event) {
    const checked = event.target.checked
    const counter = checked ? 1 : 0

    this.inputCheckboxTargets.forEach(checkbox => {
      checkbox.checked = checked
    })
    this.inputTextTargets.forEach(textField => {
      textField.value = counter.toString()
    })

    this.updateAllCheckboxes()
  }

  change (event) {
    const groups = this.zip(this.inputCheckboxTargets, this.inputTextTargets)
    groups.forEach((group) => {
      const checkBox = group[0]
      const textInput = group[1]
      textInput.value = checkBox.checked ? '1' : '0'
    })
    this.updateAllCheckboxes()
  }

  increase (event) {
    this.updateCounter(event, 1)
  }

  decrease (event) {
    this.updateCounter(event, -1)
  }

  updateCounter (event, increment) {
    const parent = this.cheboxRowTargets.filter(row => row.contains(event.target))[0]
    const textField = parent.querySelector('input[type="text"]')
    const checkboxField = parent.querySelector('input[type="checkbox"]')

    let currentValue = parseInt(textField.value || 0) + increment
    currentValue = Math.max(currentValue, 0)

    textField.value = currentValue.toString()
    checkboxField.checked = currentValue > 0

    this.updateAllCheckboxes()
  }

  updateAllCheckboxes () {
    const checkedFields = this.inputCheckboxTargets.filter(checkbox => checkbox.checked)

    if (this.hasAllCheckboxTarget) {
      const allCheckbox = this.allCheckboxTarget

      allCheckbox.checked = checkedFields.length === this.inputCheckboxTargets.length
      allCheckbox.indeterminate = checkedFields.length > 0 && checkedFields.length < this.inputCheckboxTargets.length
    }
  }

  zip (...arrays) {
    const length = Math.max(...arrays.map(arr => arr.length))
    return Array.from({ length }, (_, i) => arrays.map(arr => arr[i]))
  }

  reset (event) {
    event.preventDefault()
    // Uncheck all checkboxs
    if (this.hasAllCheckboxTarget) {
      this.allCheckboxTarget.checked = false
      this.allCheckboxTarget.indeterminate = false
    }
    this.inputCheckboxTargets.forEach(checkbox => {
      checkbox.checked = false
    })

    // Set value 0 for all input boxes
    this.inputTextTargets.forEach(inputField => {
      inputField.value = 0
    })
  }
}
