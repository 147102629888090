import { Controller } from '@hotwired/stimulus'
import { enter } from 'el-transition'

// Connects to data-controller="open-modal"
export default class extends Controller {
  static values = {
    targetElementId: String
  }

  connect () {
  }

  showTarget (event) {
    const element = document.getElementById(this.targetElementIdValue)

    enter(element)
    element.querySelectorAll('[data-transition-enter]').forEach(e => {
      enter(e)
    })
  }
}
