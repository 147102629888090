import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="time-duration-edit"
export default class extends Controller {
  connect () {
    this.boundChangeHandler = this.onChangeHandler.bind(this)
    this.element.addEventListener('change', this.boundChangeHandler)
  }

  disconnect () {
    this.element.removeEventListener('change', this.boundChangeHandler)
  }

  onChangeHandler (event) {
    const formatValue = function (h, m) {
      const im = parseInt(m)
      if (im >= 0) {
        return h.toString() + ':' + (im < 10 ? '0' : '') + im.toString()
      }
      return h
    }

    const v = this.element.value
    let m = v.match(/^\s*(\d+):(\d+)\s*$/) // Input in xx:xx format
    if (m) {
      this.element.value = formatValue(m[1], m[2])
      return
    }
    m = v.match(/^\s*(\d+)[,.](\d+)\s*$/) // Decimal format
    if (m) {
      this.element.value = formatValue(m[1], Math.round(parseFloat('0.' + m[2]) * 60))
      return
    }
    m = v.match(/^\s*(\d+)\s*$/) // Hours only
    if (m) {
      this.element.value = formatValue(m[1], 0)
    }
  }
}
