import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="tabs-mobile"
export default class extends Controller {
  static targets = ['menuSections', 'menuItem']
  static values = { url: String }

  selectTab (event) {
    if (!this.menuSectionsTargets[0].contains(event.target)) return

    event.preventDefault()

    // we set "data-selected" to false for all sections
    this.menuSectionsTargets[0].querySelectorAll('li').forEach(
      li => li.removeAttribute('data-selected')
    )

    // we set "data-selected" to true for the current section
    event.target.closest('li').setAttribute('data-selected', true)

    // we display the menu items of current section, and hide the others
    this.menuItemTargets.forEach(menuItem => {
      const classes = menuItem.classList

      if (menuItem.getAttribute('data-url') === event.target.getAttribute('href')) {
        classes.remove('hidden')
      } else {
        classes.add('hidden')
      }
    })
  }
}
