import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="event-dispatcher"
export default class extends Controller {
  // this action expects a "name" param so that it can create a CustomEvent with that name
  // for example:
  // data-action='event-dispatcher#send' data-event-dispatcher-name-param: 'connect-complete-list-auto-scroll'
  send (event) {
    event.stopPropagation() // required otherwise params are not recovered

    const { name, ...otherParams } = event.params

    if (!name) return

    const eventToDispatch = new CustomEvent(name, { detail: otherParams })
    document.dispatchEvent(eventToDispatch)
  }
}
