import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    locale: String
  }

  static targets = [
    'calendar', 'inputTarget', 'nightsNumber', 'firstDate', 'secondDate',
    'calendarUrl', 'calendarUpdatedUrl'
  ]

  initialize () {
    this.firstDateISO = undefined
    this.secondDateISO = undefined
    this.nbElements = 0
  }

  connect () {
    this.getTargetValues()
    this.setInputValue()
  }

  calendarTargetConnected () {
    this.setCalendarValues()
    this.setDisplay()

    document.addEventListener('click', this.handleClick)
  }

  calendarTargetDisconnected () {
    document.removeEventListener('click', this.handleClick)
  }

  handleClick (event) {
    // when we click outside of the calendar, we close it
    const calendar = document.querySelector('[data-date-picker-target="calendar"]')
    if (calendar && !calendar.contains(event.target)) {
      calendar.remove()
    }
  }

  click (event) {
    event.stopPropagation()
    const dateClicked = this.targetToDate(event.target)

    // if pastDatesDisabledValue is activated and the user clicks on a past date, we don't do anything
    if (this.pastDatesDisabledValue && (dateClicked < this.today())) return

    // if the second click is on a date before the date of the first click, we use the clicked date
    // as a new start point
    if (this.withRangeValue && this.firstDateISO && !this.secondDateISO &&
       (dateClicked < this.dateISOToDate(this.firstDateISO))) {
      this.firstDateISO = undefined
    }

    this.setVariables(dateClicked)
    this.setInputValue()
    this.setTargetValues()
    this.setCalendarUrl()
    this.setDisplay()
    this.close()
  }

  mouseover (event) {
    if (!this.withRangeValue || this.secondDateISO) return

    const dateOvered = this.targetToDate(event.target)

    this.secondDateISO = this.dateToISO(dateOvered)
    this.setDisplay()
    this.secondDateISO = null
  }

  getTargetValues () {
    if (this.hasFirstDateTarget) {
      this.firstDateISO = this.firstDateTarget.value
    }
    if (this.hasSecondDateTarget) {
      this.secondDateISO = this.secondDateTarget.value
    }
  }

  setInputValue () {
    if (!this.hasInputTargetTarget) {
      return
    }
    if (this.firstDateISO && this.secondDateISO) {
      this.inputTargetTarget.value = `${this.localizeDateISO(this.firstDateISO, false)} - ` +
                                     `${this.secondDateISO ? this.localizeDateISO(this.secondDateISO) : ''}`
    } else if (this.firstDateISO) {
      this.inputTargetTarget.value = this.localizeDateISO(this.firstDateISO)
    }
  }

  setTargetValues () {
    const setTargetValue = (target, value) => {
      if (value && target.value !== value.toString()) {
        target.value = value
        target.dispatchEvent(new Event('change'))
      }
    }

    if (this.hasFirstDateTarget) {
      setTargetValue(this.firstDateTarget, this.firstDateISO)
    }
    if (this.hasSecondDateTarget) {
      setTargetValue(this.secondDateTarget, this.secondDateISO)
    }
  }

  setCalendarUrl () {
    if (this.hasCalendarUrlTarget) { // it may be absent and not needed (for example for date_picker_button)
      this.calendarUrlTarget.href = this.calendarUpdatedUrlTarget.src
    }
  }

  // for values inside the turbo frames, we cannot use classic Stimulus values (they are not refreshed)
  setCalendarValues () {
    this.withRangeValue = this.calendarTarget.dataset.datePickerWithRangeValue === 'true'
    this.pastDatesDisabledValue = this.calendarTarget.dataset.datePickerPastDatesDisabledValue === 'true'
    this.withNightsNumberValue = this.calendarTarget.dataset.datePickerWithNightsNumberValue === 'true'
  }

  setVariables (date) {
    // when withRange is false, we only set firstDateISO
    // when withRange is true:
    //   - if firstDateISO is not set yet, we set firstDateISO
    //   - if firstDateISO is set, but not secondDateISO, we set secondDateISO
    //   - if secondDateISO is set, it means the user is clicking a third time,
    //     so we reinitialize secondDateISO and set firstDateISO
    if (this.withRangeValue && this.firstDateISO && !this.secondDateISO) {
      this.secondDateISO = this.dateToISO(date)
    } else {
      this.secondDateISO = undefined
      this.firstDateISO = this.dateToISO(date)
    }
  }

  setDisplay () {
    this.setDatesSelected()
    this.setNightsNumber()
  }

  setDatesSelected () {
    // we set all selected days as unselected
    document.querySelectorAll('[data-selected]').forEach(
      (element) => {
        delete element.dataset.selected
      }
    )

    const firstDate = this.firstDateISO && this.dateISOToDate(this.firstDateISO)

    if (firstDate) {
      const secondDate = this.secondDateISO && this.dateISOToDate(this.secondDateISO)

      // we set all elements between firstDate and secondDate as selected
      this.nbElements = 0
      const from = new Date(firstDate.getTime())
      const to = secondDate && (secondDate > firstDate) ? secondDate : firstDate
      /* eslint-disable no-unmodified-loop-condition */
      for (let date = from; date <= to; date.setUTCDate(date.getUTCDate() + 1)) {
        const element = this.dateToElement(date)

        if (element) {
          element.dataset.selected = this.selectType(date, firstDate, secondDate)
        }

        this.nbElements++
      }
      /* eslint-enable no-unmodified-loop-condition */
    }
  }

  setNightsNumber () {
    if (!this.withNightsNumberValue) return

    this.nightsNumberTarget.innerHTML = Math.max(0, this.nbElements - 1)
  }

  close () {
    if (this.withRangeValue) {
      if (this.firstDateISO && this.secondDateISO) this.calendarTarget.remove()
    } else {
      if (this.firstDateISO) this.calendarTarget.remove()
    }
  }

  localizeDateISO (dateISO, withYear = true) {
    return this.dateISOToDate(dateISO).toLocaleString(
      this.localeValue === 'en' ? 'en-gb' : (this.localeValue || 'en-gb'),
      { timeZone: 'UTC', year: withYear ? 'numeric' : undefined, month: 'short', day: 'numeric', weekday: 'short' }
    ).replace(/,/g, '').replace(/(^|\s)(\p{L})/ug, (m, p1, p2) => p1 + p2.toUpperCase())
  }

  dateToElement (date) {
    return document.querySelector(`[data-year='${date.getFullYear()}']` +
                                  `[data-month='${date.getUTCMonth() + 1}']` +
                                  `[data-day='${date.getUTCDate()}']`)
  }

  dateISOToDate (dateISO) {
    const dateISOSplit = dateISO.split('-')
    return new Date(Date.UTC(dateISOSplit[0], parseInt(dateISOSplit[1]) - 1, dateISOSplit[2]))
  }

  targetToDate (target) {
    return new Date(Date.UTC(target.dataset.year, parseInt(target.dataset.month) - 1, parseInt(target.dataset.day)))
  }

  today () {
    const today = new Date()
    today.setUTCHours(0, 0, 0, 0)
    return today
  }

  dateToISO (date) {
    return date.toISOString().split('T')[0]
  }

  selectType (date, firstDate, secondDate) {
    const isFirstSelected = firstDate.getTime() === date.getTime()
    const isSecondSelected = secondDate && (secondDate.getTime() === date.getTime())

    if (!this.withRangeValue) {
      return 'single'
    } else if (isFirstSelected) {
      return 'begin'
    } else if (isSecondSelected) {
      return 'end'
    } else {
      return 'between'
    }
  }
}
