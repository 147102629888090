import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="tabs"
export default class extends Controller {
  static targets = ['tab']

  selectTab (event) {
    const tabId = event.params.id

    // Show correct page
    if (this.hasTabTarget) {
      this.tabTargets.forEach((pageElement) => {
        if (pageElement.id === tabId) {
          pageElement.classList.remove('hidden')
        } else {
          pageElement.classList.add('hidden')
        }
      })
    }

    // Style selected tab
    const selectedTabClasses = ['border-b-2', 'border-accent-600', 'text-neutral-800']
    const unselectedTabClasses = ['text-neutral-600']
    event.target.closest('ul').querySelectorAll('li').forEach((tabElem) => {
      const classes = tabElem.classList
      classes.remove(...selectedTabClasses)
      classes.add(...unselectedTabClasses)
    })
    const classes = event.target.closest('li').classList
    classes.add(...selectedTabClasses)
    classes.remove(...unselectedTabClasses)
  }
}
