import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'

// Connects to data-controller="expander"
export default class extends Controller {
  static targets = ['child', 'ariaController', 'submitBtn']

  connect () {
    this.visible = this.element.hasAttribute('data-expanded')
    this.#updateAriaExpanded(this.visible)
  }

  toggle (event) {
    event.preventDefault()
    this.visible = !this.visible
    this.#setState(this.visible)
    this.#updateAriaExpanded(this.visible)
    this.element.toggleAttribute('data-expanded')
  }

  hide (event) {
    if (!this.element.contains(event.target)) {
      this.forceHide()
    }
  }

  hideAndSubmit (event) {
    if (this.childTarget.classList.contains('hidden')) {
      return
    }
    this.hide(event)
    if (this.hasSubmitBtnTarget) {
      if (!this.childTarget.contains(event.target) && !this.ariaControllerTarget.contains(event.target)) {
        this.childTarget.classList.add('hidden')
        this.submitBtnTarget.click()
      }
    }
  }

  forceHide () {
    if (!this.visible) {
      return
    }
    this.visible = false
    this.childTargets.forEach((child) => { leave(child) })
    delete this.element.dataset.expanded
    this.#updateAriaExpanded(this.visible)
  }

  #setState (visible) {
    this.childTargets.forEach((child) => {
      if (visible) {
        child.classList.remove('hidden')
        enter(child)
      } else {
        leave(child)
      }
    })
  }

  #updateAriaExpanded (visible) {
    if (this.hasAriaControllerTarget) {
      this.ariaControllerTarget.setAttribute('aria-expanded', visible)
    }
  }
}
