import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['options', 'input']

  handleIntervalFieldChange (event) {
    this.optionsTarget.dataset.repeat = event.target.value
    this.inputTarget.dataset.repeat = event.target.value
  }
}
