import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="pos-product-buttons"
export default class extends Controller {
  change (event) {
    event.currentTarget.querySelector("[name*='[row]']").value = event.detail.newRow
    event.currentTarget.querySelector("[name*='[column]']").value = event.detail.newCol

    // we submit the form only for the target change
    if (event.detail.type === 'target') this.element.querySelector('form').requestSubmit()
  }
}
