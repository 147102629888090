import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="table-row-memento"
export default class extends Controller {
  static values = {
    storageKey: String // The storage key holding the persisted state
  }

  static targets = ['row']

  connect () {
    const initiallyShown = JSON.parse(localStorage.getItem(this.storageKeyValue))

    if (initiallyShown) {
      initiallyShown.forEach(id => {
        const elements = document.querySelectorAll(`[data-row-id='${id}']`)
        elements.forEach(element => {
          element.dataset.expanded = 'true'
        })
      })
    }
  }

  save () {
    const expandedIds = this.rowTargets
      .filter(element => element.dataset.expanded === 'true')
      .map(element => element.dataset.rowId)

    localStorage.setItem(this.storageKeyValue, JSON.stringify(expandedIds))
  }
}
