import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'image']

  remove () {
    this.inputTarget.value = ''
    this.imageTarget.remove()
    this.element.classList.add('hidden')

    document.querySelector("div[data-abstract-upload-target='dropZone']").classList.remove('hidden')
  }
}
