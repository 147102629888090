import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="time-slot-configuration"
export default class extends Controller {
  connect () {
  }

  preSubmit (event) {
    if (this.onChangeTimerID) {
      clearTimeout(this.onChangeTimerID)
    }
  }

  onChange (event) {
    const f = () => {
      this.element.preview.value = '1'
      this.element.requestSubmit()
    }
    // We need to wait and cancel on change if submit button was pressed
    this.onChangeTimerID = setTimeout(f.bind(this), 300)
  }
}
