import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="dynamic-field-row"
export default class extends Controller {
  static targets = ['destroyField', 'content', 'icon']

  destroy (event) {
    event.preventDefault()
    this.destroyFieldTarget.value = 'true'
    this.element.hidden = true
  }

  toggle (event) {
    event.preventDefault()
    this.contentTarget.classList.toggle('hidden')
    this.iconTarget.classList.toggle('rotate-180')
  }
}
