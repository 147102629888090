import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="turbo-frame-reload"
export default class extends Controller {
  connect () {
    // we reload the element only if it is visible (it has an offsetParent) and if
    // it has already been loaded once (complete is present)
    if (this.element.offsetParent && this.element.getAttribute('complete') === '') {
      this.element.reload()
    }
  }
}
