import { Controller } from '@hotwired/stimulus'

// Connects to data-controller=' booking-account-selector'
export default class extends Controller {
  static targets = ['input', 'suggestions', 'item', 'bookingAccountMenuTemplate']

  filterItems () {
    const ulElement = this.suggestionsTarget
    const inputElement = this.inputTarget
    const liElements = ulElement.querySelectorAll('li')

    liElements.forEach((item, _index) => {
      this.setBookingAccountValue(inputElement.value, this.inputTarget.id)
      if (inputElement.value === '') {
        item.innerHTML = item.innerText

        item.classList.remove('hide')
        item.classList.add('show')
      } else {
        const inputText = inputElement.value
        const itemText = item.innerText
        const i = itemText.toLowerCase().indexOf(inputText.toLowerCase())
        if (i === -1) {
          item.classList.remove('show')
          item.classList.add('hide')

          this.setBookingAccountValue(inputElement.value, this.inputTarget.id)
        } else {
          item.innerHTML = itemText.slice(0, i) + "<span class='hl'>" + itemText.slice(i, i + inputText.length) + '</span>' + itemText.slice(i + inputText.length)

          item.classList.remove('hide')
          item.classList.add('show')
        }
      }
    })

    const noMatch = this.itemTargets.every(element => element.classList.value === 'hide')
    if (noMatch) {
      this.suggestionsTarget.classList.add('hide')
    } else {
      this.suggestionsTarget.classList.remove('hide')
    }
  }

  selectValue (event) {
    const rowIndex = event.currentTarget.dataset.rowIndex

    // Following IF BLOCK is only for expense page
    const amountField = document.getElementById('amount-' + rowIndex)
    if (amountField) {
      const vatRate = event.currentTarget.dataset.vatRate
      amountField.setAttribute('data-vat-rate', vatRate)
    }

    const selectedValue = event.currentTarget.innerText
    document.getElementById(rowIndex).value = selectedValue
    this.setBookingAccountValue(selectedValue, rowIndex)
    event.currentTarget.parentElement.classList.add('hide')
  }

  setBookingAccountValue (value, idPostfix) {
    const matchedValue = value.match(/\d+/)
    if (matchedValue) {
      document.getElementById('booking-account-' + idPostfix).value = matchedValue[0].replace(/[{()}]/g, '')
    } else {
      document.getElementById('booking-account-' + idPostfix).value = ''
    }
  }
}
