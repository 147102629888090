import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'
import { put } from '@rails/request.js'

export default class extends Controller {
  static targets = ['positionField']
  static values = { url: String }
  connect () {
    this.sortable = Sortable.create(this.element, {
      handle: '.handle',
      draggable: '.item',
      onEnd: this.end.bind(this)
    })
  }

  disconnect () {
    this.sortable.destroy()
  }

  end (event) {
    if (!this.hasUrlValue) {
      this.positionFieldTargets.forEach((target, index) => {
        target.value = index
      })
    } else { this.save(event) }
  }

  async save (event) {
    const id = event.item.getAttribute('data-id')
    const newIndex = event.newIndex + 1

    try {
      await put(this.url(id), { body: { position: newIndex } })
    } catch (err) {}
  }

  url (id) {
    return this.urlValue.replace(':id', id)
  }
}
