import ParentDragDropController from '../javascript/controllers/parent_drag_drop_controller.js'

export default class extends ParentDragDropController {
  drop (element) {
    const targetElement = element?.closest('[data-col][data-row]')

    // we want to make sure that we don't drag and drop on the same place, and that we don't
    // drop on another grid
    if (targetElement && targetElement !== this.sourceElement && this.element.contains(element)) {
      // we copy the HTML content
      const sourceHTML = this.sourceElement.innerHTML
      const targetHTML = targetElement.innerHTML

      targetElement.innerHTML = sourceHTML
      this.sourceElement.innerHTML = targetHTML

      // we copy the data-action
      const sourceDataAction = this.sourceElement.dataset.action
      const targetDataAction = targetElement.dataset.action

      sourceDataAction ? targetElement.dataset.action = sourceDataAction : delete targetElement.dataset.action
      targetDataAction ? this.sourceElement.dataset.action = targetDataAction : delete this.sourceElement.dataset.action

      // we copy the classes
      const sourceClasses = this.sourceElement.className
      const targetClasses = targetElement.className

      targetElement.className = sourceClasses
      this.sourceElement.className = targetClasses

      // we trigger a change event on both source and target elements
      this.sendChangeEvent(this.sourceElement, targetElement)

      this.sourceElement = undefined
    } else {
      this.sourceElement = undefined
      this.elementClicked.click()
    }
  }

  sendChangeEvent (sourceElement, targetElement) {
    // we need to use "setTimeout" otherwise the event is not correctly received by the element
    setTimeout(() => {
      const sourceDetail = {
        type: 'source',
        previousRow: targetElement.dataset.row,
        previousCol: targetElement.dataset.col,
        newRow: sourceElement.dataset.row,
        newCol: sourceElement.dataset.col
      }
      const sourceFirstElement = sourceElement.children[0]
      if (sourceFirstElement) sourceFirstElement.dispatchEvent(new CustomEvent('change', { detail: sourceDetail }))

      const targetDetail = {
        type: 'target',
        previousRow: sourceElement.dataset.row,
        previousCol: sourceElement.dataset.col,
        newRow: targetElement.dataset.row,
        newCol: targetElement.dataset.col
      }
      const targetFirstElement = targetElement.children[0]
      if (targetFirstElement) targetFirstElement.dispatchEvent(new CustomEvent('change', { detail: targetDetail }))
    })
  }
}
