import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['image', 'bullet']

  bulletClick (event) {
    const indexClicked = event.params.index
    this.updateSlider(indexClicked)
  }

  previous (_event) {
    const indexDisplayed = this.getIndexDisplayed()
    const newIndex = indexDisplayed > 0 ? indexDisplayed - 1 : this.imageTargets.length - 1
    this.updateSlider(newIndex)
  }

  next (_event) {
    const indexDisplayed = this.getIndexDisplayed()
    const newIndex = indexDisplayed < this.imageTargets.length - 1 ? indexDisplayed + 1 : 0
    this.updateSlider(newIndex)
  }

  updateSlider (index) {
    this.selectElement(this.imageTargets, index)
    this.selectElement(this.bulletTargets, index)
  }

  getIndexDisplayed () {
    for (let i = 0; i < this.imageTargets.length; i++) {
      if (this.imageTargets[i].hasAttribute('data-selected')) return i
    }
  }

  // selectElement sets data-select to the element at the "index" of the collection
  // and removes it from all the other elements
  selectElement (collection, index) {
    for (let i = 0; i < collection.length; i++) {
      const element = collection[i]
      if (i === index) {
        element.dataset.selected = ''
      } else {
        delete element.dataset.selected
      }
    }
  }
}
