import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  contentChanged = false

  markContentAsChanged () {
    this.contentChanged = true
  }

  submitFormIfChanged () {
    if (this.contentChanged) {
      this.submitForm()
      this.contentChanged = false // Reset the flag after submitting the form
    }
  }

  submitForm () {
    this.element.requestSubmit()
  }

  clickClosestSubmit (event) {
    let element = event.currentTarget

    while (element) {
      element = element.parentNode

      if (!element || element === this.element) break

      const submit = element.querySelector('[type="submit"]')

      if (submit) {
        setTimeout(() => submit.click(), 0)
        break
      }
    }
  }
}
