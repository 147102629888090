// app/components/abstract_upload_controller.js

import { Controller } from '@hotwired/stimulus'
import { DirectUpload } from '@rails/activestorage'

class Upload {
  constructor (file, outputTarget, progressTemplate) {
    this.file = file
    this.directUpload = new DirectUpload(file, '/rails/active_storage/direct_uploads', this)
    this.outputTarget = outputTarget
    this.progressTemplate = progressTemplate
  }

  process () {
    this.insertUpload()
    return new Promise((resolve, reject) => {
      this.directUpload.create((error, blob) => {
        if (error) {
          reject(error) // Reject the promise if there's an error
        } else {
          resolve(blob) // Resolve the promise with the blob data
        }
      })
    })
  }

  insertUpload (upload) {
    const fileUpload = this.progressTemplate.querySelector('div.relative')
    fileUpload.id = `upload_${this.directUpload.id}`

    const imageField = this.progressTemplate.querySelector('img')
    const reader = new FileReader()
    reader.onload = function () {
      imageField.src = reader.result
    }
    reader.readAsDataURL(this.file)
    this.outputTarget.appendChild(fileUpload)
  }

  directUploadWillStoreFileWithXHR (request) {
    request.upload.addEventListener('progress', (event) => this.updateProgress(event))
  }

  updateProgress (event) {
    const percentage = (event.loaded / event.total) * 100
    if (percentage === 100 && this.outputTarget.getAttribute('data-name') === 'image-component') {
      document.querySelector(`#upload_${this.directUpload.id}`).remove()
    }
  }
}

export default class extends Controller {
  static targets = ['input', 'template', 'output', 'destroyField',
    'errors', 'progressTemplate', 'photosSubmitBtn', 'dropZone', 'imageButton', 'multiple']

  connect () {
    this.element.addEventListener('dragover', this.preventDragDefaults)
    this.element.addEventListener('dragenter', this.preventDragDefaults)
  }

  disconnect () {
    this.element.removeEventListener('dragover', this.preventDragDefaults)
    this.element.removeEventListener('dragenter', this.preventDragDefaults)
  }

  preventDragDefaults (e) {
    e.preventDefault()
    e.stopPropagation()
  }

  selectImage (event) {
    event.preventDefault()
    this.inputTarget.click()
  }

  async dropPhotos (event) {
    event.preventDefault()

    if (this.hasDropZoneTarget) {
      this.dropZoneTarget.classList.add('hidden')
    }
    this.imageButtonTarget.classList.remove('hidden')
    const files = event.dataTransfer.files

    await this.processFiles(files)
  }

  async uploadPhotos () {
    const input = this.inputTarget
    if (this.hasDropZoneTarget) {
      this.dropZoneTarget.classList.add('hidden')
    }

    if (this.hasImageButtonTarget) {
      this.imageButtonTarget.classList.remove('hidden')
    }

    await this.processFiles(input.files)
  }

  async processFiles (files) {
    const template = this.templateTarget
    const progressTemplate = this.progressTemplateTarget
    const output = this.outputTarget
    const multiple = this.multipleTarget.value

    if (files && files.length > 0) {
      // Loop through each file asynchronously
      for (const file of files) {
        try {
          // Await the completion of uploadFile
          let templateclassList = ''
          const progressClonedTemplate = progressTemplate.cloneNode(true)
          const progressDiv = progressClonedTemplate.content.querySelector('div.relative')
          const existingImagesCount = output.querySelectorAll('.photo-container').length

          templateclassList = existingImagesCount >= 2 ? 'col-span-2' : 'col-span-3'
          progressDiv.classList.add(templateclassList)

          const blob = await new Upload(file, output, progressClonedTemplate.content).process()

          // Update the template elements
          const clonedTemplate = template.cloneNode(true)
          const imgElement = clonedTemplate.content.querySelector('img')
          imgElement.src = '/rails/active_storage/blobs/' + blob.signed_id + '/' + blob.filename

          const positionField = clonedTemplate.content.querySelector('.positionField')
          if (positionField) {
            positionField.value = output.querySelectorAll('.photo-container').length
          }

          // Clone and insert the template before the current element
          if (this.hasOutputTarget) {
            const hiddenInput = clonedTemplate.content.querySelector("input[type='hidden']")
            hiddenInput.value = blob.signed_id

            clonedTemplate.innerHTML = clonedTemplate.innerHTML.replace(/__CHILD_INDEX__/g, new Date().getTime().toString())

            if (multiple === 'true') {
              output.appendChild(clonedTemplate.content)
            } else {
              output.innerHTML = clonedTemplate.innerHTML
            }
          }
        } catch (error) {
          const errorDiv = document.createElement('div')
          errorDiv.innerHTML = `Error uploading file ${file.name}`
          this.errorsTarget.appendChild(errorDiv)
        }
      }
      if (this.hasPhotosSubmitBtnTarget) {
        this.photosSubmitBtnTarget.click()
      }
    }
  }

  remove (event) {
    event.preventDefault()
    const target = event.currentTarget

    // we remove the image server side by setting an empty value to the input
    target.parentElement.querySelector('input').value = ''

    // we remove the image and the remove button
    target.parentElement.querySelector('img').remove()
    target.remove()

    // in case of a single image, we display the dropzone again
    if (this.multipleTarget.value !== 'true') {
      this.dropZoneTarget.classList.remove('hidden')
    }
  }
}
