import ParentDragDropController from './parent_drag_drop_controller.js'

export default class extends ParentDragDropController {
  drop (element) {
    const targetElement = element?.closest('.photo-container')

    // we want to make sure that we don't drag and drop on the same place, and that we don't
    // drop on another grid
    if (targetElement && targetElement !== this.sourceElement && this.element.contains(element)) {
      // we copy the HTML content
      const sourceHTML = this.sourceElement.innerHTML
      const targetHTML = targetElement.innerHTML

      targetElement.innerHTML = sourceHTML
      this.sourceElement.innerHTML = targetHTML

      // we copy the data-action
      const sourceDataAction = this.sourceElement.dataset.action
      const targetDataAction = targetElement.dataset.action

      sourceDataAction ? targetElement.dataset.action = sourceDataAction : delete targetElement.dataset.action
      targetDataAction ? this.sourceElement.dataset.action = targetDataAction : delete this.sourceElement.dataset.action

      // Copy position
      const targetPositionField = targetElement.querySelector('.positionField')
      const sourcePositionField = this.sourceElement.querySelector('.positionField')

      const targetPositionFieldValue = targetPositionField.value
      const sourcePositionFieldValue = sourcePositionField.value

      targetPositionField.value = sourcePositionFieldValue
      sourcePositionField.value = targetPositionFieldValue

      this.sourceElement = undefined
    } else {
      this.sourceElement = undefined
      this.elementClicked.click()
    }
  }
}
