import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="text-duplicator"
export default class extends Controller {
  static targets = ['field']

  duplicate (event) {
    const target = this.fieldTargets[event.params.fieldIndex || 0]
    if (!target.value || target.value === event.detail.oldValue) {
      target.value = event.detail.newValue
    }
  }
}
