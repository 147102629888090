import M from 'materialize-css/dist/js/materialize'
import { PlacementDialog } from './table_placement_dialog.ts'
import Flash from './flash.ts'

var seatingApp : SeatingApp;

class Party {
  guests : number;
  children : number;
  bookingId : string;

  private _ui : HTMLElement;

  createUI(app : HTMLElement) {
    let tpl = document.getElementById('seating-party-tpl') as HTMLTemplateElement;
    this._ui = tpl.content.children[0].cloneNode(true) as HTMLElement;
    app.querySelector('.table-seating-controls').append(this._ui);

    let s = this.guests.toString();
    if (this.children > 0) {
      s += '+' + this.children;
    }
    let span = this._ui.querySelector('span') as HTMLElement;
    span.innerText = s;
  }

  teardown() {
    this._ui.remove();
  }

  toJSON() {
    return {
      guests: this.guests,
      children: this.children,
      booking_id: this.bookingId
    }
  }
};

class TablePlacementFlow {
  private _app : HTMLElement;
  private _overlays : HTMLElement[];
  private _setPlacementEventHandler;

  constructor(app : HTMLElement) {
    this._app = app;
    this._overlays = [];
    this.addTableOverlays();
    this._app.classList.add('table-placement-mode');
    this._setPlacementEventHandler = this.onSetPlacement.bind(this);
    this._app.querySelector('.set-placement-btn').addEventListener('click',
      this._setPlacementEventHandler, true);
    this._app.querySelector('.set-placement-btn').setAttribute('data-selected-count',
      '0');
  }

  private addTableOverlays() {
    this._app.querySelectorAll('.table').forEach((e) => {
      let overlay = document.createElement('div') as HTMLElement;
      overlay.classList.add('table-placement-overlay');
      (e as HTMLElement).append(overlay);
      overlay.onclick = this.onSelectTable.bind(this);
      this._overlays.push(overlay);
    });
  }

  private onSelectTable(event) {
    event.stopPropagation();
    let table = event.target.closest('.table');
    table.classList.toggle('selected');
    this._app.querySelector('.set-placement-btn').setAttribute('data-selected-count',
      this._app.querySelectorAll('.table.selected').length.toString());
  }

  private getSelectedTableIDs() {
    let selected = [];
    this._app.querySelectorAll('.table.selected').forEach(e => selected.push(e.getAttribute('data-id')));
    return selected.join(',');
  }

  private onSetPlacement(event) {
    event.preventDefault();
    let ids = this.getSelectedTableIDs();
    if (ids == '') {
      return;
    }
    let url = this._app.getAttribute('data-set-placement-url');
    url = url.replace('NNN', ids);
    fetch(url, {
      method: 'GET',
    })
    .then(response => response.text())
    .then(data => {
      let cont = document.getElementById('dialog-container');
      cont.innerHTML = data;
      let dlg = new PlacementDialog(cont.querySelector('.modal'));
      dlg.onCancel = () => this.teardown();
    })
    .catch((error) => {
      //flash_failure('Ett tekniskt fel inträffade');
      console.error('Error:', error);
    });
  }

  teardown() {
    this._app.querySelectorAll('.table.selected').forEach(e => e.classList.remove('selected'));
    this._overlays.forEach(e => e.remove());
    this._app.classList.remove('table-placement-mode');
    this._app.querySelector('.set-placement-btn').removeEventListener('click',
      this._setPlacementEventHandler, true);
    this._app.querySelector('.set-placement-btn').setAttribute('data-selected-count',
      '0');
  }
};

class SeatFlow {
  private _app : HTMLElement;
  private _dlg : HTMLElement;
  private _bookingId : string;
  private _tableId : string;
  private _seatingParty : Party;
  private _overlays : HTMLElement[];

  onSeat : (url : string, party : Party) => void;
  onCancel : () => void;

  constructor(app : HTMLElement) {
    this._app = app;
    this._overlays = [];
    let dialogUrl = this._app.getAttribute('data-seat-dialog-url');
    fetch(dialogUrl, {
      method: 'GET',
    })
    .then(response => response.text())
    .then(data => {
      let cont = document.getElementById('dialog-container');
      cont.innerHTML = data;
      this._dlg = document.getElementById('seating-dlg');
      this.setupDialog();
    })
    .catch((error) => {
      Flash.failure('Ett tekniskt fel inträffade');
      console.error('Error:', error);
    });
  }

  private setupDialog() {
    this._dlg.querySelectorAll('.rve-cancel-dlg').forEach((e) => {
      (e as HTMLElement).onclick = (event) => {
        event.preventDefault();
        let dlg = e.closest('.modal');
        M.Modal.getInstance(dlg).close();
        this.teardown();
        this.onCancel();
      };
    });

    this._dlg.querySelectorAll('.select-party-btn').forEach((e) => {
      (e as HTMLElement).onclick = this.onSelectParty.bind(this);
    });

    (this._dlg.querySelector('.rve-seat') as HTMLElement).onclick = this.onSeatClick.bind(this);
    M.Modal.init(this._dlg, {dismissible: false});
    M.Modal.getInstance(this._dlg).open();
  }

  private onSeatClick(event) {
    event.preventDefault();
    this._seatingParty = new Party;
    this._seatingParty.guests = Number((this._dlg.querySelector('input[name=guests]') as HTMLInputElement).value);
    this._seatingParty.children = Number((this._dlg.querySelector('input[name=children]') as HTMLInputElement).value);
    if (this._bookingId) {
      this._seatingParty.bookingId = this._bookingId;
    }
    M.Modal.getInstance(this._dlg).close();
    this.setupSeatingMode();
  }

  private setupSeatingMode() {
    this.addTableOverlays();
    this._seatingParty.createUI(this._app);
    this._app.classList.add('seating-mode');
  }

  private onSelectParty(event) {
    event.preventDefault();
    let name = event.currentTarget.getAttribute('data-name') || 'Drop';
    let guests = event.currentTarget.getAttribute('data-guests');
    let children = event.currentTarget.getAttribute('data-children');
    this._bookingId = event.currentTarget.getAttribute('data-booking-id');
    this._tableId = event.currentTarget.getAttribute('data-table-id');
    (this._dlg.querySelector('.booking-name') as HTMLInputElement).innerText = name;
    (this._dlg.querySelector('input[name=guests]') as HTMLInputElement).value = guests;
    (this._dlg.querySelector('input[name=children]') as HTMLInputElement).value = children;
    this._dlg.setAttribute('data-step', 'set-guests');

    let preSeatedTable = document.getElementById('table_' + this._tableId) as HTMLElement;
    if (preSeatedTable) {
      if (preSeatedTable.getAttribute('data-state') != 'occupied') {
        preSeatedTable.classList.add('pre-seated');
      }
    }
  }

  private onSelectTable(event) {
    event.stopPropagation();
    let table = event.target.closest('.table');
    this.teardown();
    this.onSeat(table.getAttribute('data-seat-url'), this._seatingParty);
  }

  private addTableOverlays() {
    let tpl = document.getElementById('seat-overlay-tpl') as HTMLTemplateElement;
    this._app.querySelectorAll('.table').forEach((e) => {
      let overlay = tpl.content.querySelector('.seat-overlay').cloneNode(true) as HTMLElement;
      (e as HTMLElement).append(overlay);
      let seatable = e.getAttribute('data-state') != 'occupied';
      if (seatable) {
        e.classList.add('seatable');
        overlay.onclick = this.onSelectTable.bind(this);
      }
      this._overlays.push(overlay);
    });
  }

  private removeTableOverlays() {
    this._app.querySelectorAll('.table.seatable').forEach(e => e.classList.remove('seatable'));
  }

  teardown() {
    this._overlays.forEach(e => e.remove());
    document.getElementById('dialog-container').innerHTML = '';
    this._app.classList.remove('seating-mode');
    this.removeTableOverlays();
    if (this._seatingParty) {
      this._seatingParty.teardown();
    }
    this._app.querySelectorAll('.table.pre-seated').forEach(e => e.classList.remove('pre-seated'));
  }
};

class SeatingApp {
  private _root : Element;
  private _seatFlow : SeatFlow;
  private _tablePlacementFlow : TablePlacementFlow;

  constructor(root : Element) {
    this._root = root;
    this._root.querySelectorAll('.table').forEach((e) => {
      (e as HTMLElement).onclick = this.onTableClick.bind(this);
    });

    (root.querySelector('.seat-btn') as HTMLElement).onclick = (event) => {
      event.preventDefault();
      this._seatFlow = new SeatFlow(this._root as HTMLElement);
      this._seatFlow.onSeat = this.seatParty.bind(this);
      this._seatFlow.onCancel = () => { this._seatFlow = null; }
    };
    (root.querySelector('.cancel-btn') as HTMLElement).onclick = (event) => {
      event.preventDefault();
      if (this._seatFlow) {
        this._seatFlow.teardown();
        this._seatFlow = null;
      }
      if (this._tablePlacementFlow) {
        this._tablePlacementFlow.teardown();
        this._tablePlacementFlow = null;
      }
    };
    (root.querySelector('.refresh-btn') as HTMLElement).onclick = (event) => {
      event.preventDefault();
      this.refresh((event.currentTarget as HTMLElement).getAttribute('href'));
    };
    (root.querySelector('.table-placement-btn') as HTMLElement).onclick = (event) => {
      event.preventDefault();
      this._tablePlacementFlow = new TablePlacementFlow(this._root as HTMLElement);
    };
  }

  private onTableClick(event) {
    event.preventDefault();
    if (!this.isSeatingMode()) {
      window.location = event.currentTarget.getAttribute('data-info-url');
    }
  }

  private seatParty(url : string, party : Party) {
    fetch(url, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({party: party.toJSON()}),
    })
    .then(response => response.json())
    .then(data => {
      if (data.msg != '') {
        Flash.failure(data.msg);
      }
      this.updateTables(data.tables);
    })
    .catch((error) => {
      Flash.failure('Ett tekniskt fel inträffade');
      console.error('Error:', error);
    });
  }

  private updateTables(data) {
    data.forEach((t) => {
      let tableElem = document.getElementById(t.dom_id);
      tableElem.setAttribute('data-state', t.state);
      tableElem.setAttribute('data-warning', t.state != 'free' && t.placement == '' ? '1' : '0');
      tableElem.setAttribute('data-got-placement', t.placement == '' ? '0' : '1');
      (tableElem.querySelector('.booked-at') as HTMLElement).innerText = t.booked_at;
      (tableElem.querySelector('.late-time') as HTMLElement).innerText = t.late_time;
      (tableElem.querySelector('.seating-time') as HTMLElement).innerText = t.seating_time;
    });
  }

  private isSeatingMode() : boolean {
    return this._root.classList.contains('seating-mode');
  }

  private refresh(url) {
    fetch(url, {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
    })
    .then(response => response.json())
    .then(data => {
      this.updateTables(data);
    })
    .catch((error) => {
      Flash.failure('Ett tekniskt fel inträffade');
      console.error('Error:', error);
    });
  }
};

document.addEventListener('turbo:load', () => {
  let e = document.getElementById('seating-app');
  if (e != null) {
    seatingApp = new SeatingApp(e);
  }
});
