import { Controller } from '@hotwired/stimulus'

// Connects to data-controller='total-expense-calculator'
export default class extends Controller {
  static targets = ['hiddenTotalAmount', 'hiddenTotalVat', 'amountWithVat', 'totalVatAmountDisplay', 'totalVatAmount']

  setTotalAmount (event) {
    this.hiddenTotalAmountTarget.value = this.parseAmount(event.target.value).toString()
    this.calculate()
  }

  setTotalVat (event) {
    this.hiddenTotalVatTarget.value = this.parseAmount(event.target.value).toString()
    this.calculate()
  }

  setAmountWithVatSuffix (event) {
    const amount = event.target.value
    const amountField = event.target.nextElementSibling
    const vatRate = amountField.dataset.vatRate

    if (vatRate) {
      const amountWithVat = this.amountWithVatSuffix(amount, vatRate)
      if (amountWithVat) {
        amountField.value = amountWithVat.amount.toString()
      } else {
        amountField.value = this.parseAmount(amount).toString()
      }
    } else {
      amountField.value = this.parseAmount(amount).toString()
    }
    this.calculate()
  }

  reSetAmountWithVatSuffix (event) {
    const vatFieldId = event.target.dataset.vatFieldId
    this.element.querySelector(vatFieldId).value = '0'
    this.calculate()
  }

  calculate () {
    const totalIncVat = parseInt(this.hiddenTotalAmountTarget.value)
    const totalVat = parseInt(this.hiddenTotalVatTarget.value)
    let totalExVat = totalIncVat - totalVat
    this.amountWithVatTargets.forEach(e => {
      const value = parseInt(e.value)
      if (!isNaN(value)) {
        totalExVat -= value
      }
    })
    this.totalVatAmountDisplayTarget.innerText = this.formatAmount(totalExVat)
    this.totalVatAmountTarget.value = totalExVat.toString()
  }

  formatAmount (n) {
    if (isNaN(n)) {
      return ''
    }
    let s = ''
    if (n < 0) {
      s = '-'
      n = -n
    }
    if (n === 0) {
      s += '000'
    } else if (n < 10) {
      s += '00' + n
    } else if (n < 100) {
      s += '0' + n
    } else {
      s += n.toString()
    }
    return s.slice(0, -2) + ',' + s.slice(-2)
  }

  parseAmount (s) {
    const parts = s.replaceAll(' ', '').replace(',', '.').split('.')
    if (parts.length === 1) {
      parts.push('00')
    } else {
      if (parts[1].length === 1) {
        parts[1] += '0'
      } else if (parts[1].length > 2) {
        parts[1] = parts[1].substring(0, 2)
      }
    }

    return parseInt(parts[0]) * 100 + parseInt(parts[1])
  }

  amountWithVatSuffix (s, vr) {
    const vatRate = parseInt(vr)
    const re = /^(\d+)[.,]?(\d*)([ei])$/
    const match = s.match(re)
    if (!match) {
      return undefined
    }
    let decimals = match[2] || '0'
    if (decimals.length === 1) {
      decimals += '0'
    }
    const amount = (parseInt(match[1]) * 100 + parseInt(decimals.substring(0, 2)))
    let vatExcl = 0
    let vat = 0
    if (match[3] === 'e') {
      vatExcl = amount
      vat = Math.round(amount * vatRate / 100)
    } else {
      vatExcl = Math.round(amount * 100 / (vatRate + 100))
      vat = amount - vatExcl
    }
    return { amount: vatExcl, vat }
  }
}
