import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['select', 'selectedOption', 'countryFlag']

  connect () {
    this.updateFlag()
  }

  updateFlag () {
    if (this.hasSelectedOptionTarget) {
      const option = this.selectTarget.options[this.selectTarget.selectedIndex]
      const flag = option.dataset.flag
      this.selectedOptionTarget.innerText = option.text

      // Remove existing flag class
      if (this.countryFlagTarget.dataset.currentFlag) {
        this.countryFlagTarget.classList.remove(`flag-${this.countryFlagTarget.dataset.currentFlag}`)
      }

      // Add new flag class
      this.countryFlagTarget.classList.add(`flag-${flag}`)
      this.countryFlagTarget.dataset.currentFlag = flag
    }
  }
}
