function zeroPrefix(s) {
  if (s.length == 1) {
    return '0' + s;
  }
  return s;
}

export default class TimeField {
  public static initAll() {
    document.querySelectorAll('.js-time-field').forEach((e) => {
      TimeField.init(e as HTMLElement);
    });
  }

  public static resetAll() {
    document.querySelectorAll('.js-time-field').forEach((e) => {
      (e as HTMLElement).classList.remove('invalid');
    });
  }

  public static validateAll() {
    document.querySelectorAll('.js-time-field').forEach((e) => {
      TimeField.validate(e as HTMLInputElement);
    });
  }

  public static init(e : HTMLElement) {
    e.onchange = (event : Event) => {
      let target = event.target as HTMLInputElement;
      target.classList.remove('invalid');
      let v = target.value;
        let m = v.match(/^\s*(\d\d?)\s*$/);
        if (m) {
          target.value = zeroPrefix(m[1]) + ':00';
          return;
        }

      m = v.match(/^\s*(\d{4})\s*$/);
      if (m) {
        target.value = m[1].substr(0, 2) + ':' + m[1].substr(2, 2);
        return;
      }

      m = v.match(/^\s*(\d+)[.:-](\d+)\s*$/);
      if (m) {
        target.value = zeroPrefix(m[1]) + ':' + zeroPrefix(m[2]);
        return;
      }

      target.classList.add('invalid');
    }
  }

  public static validate(e : HTMLInputElement) {
    e.classList.remove('invalid');
    let parts = e.value.split(':');
    if (parts.length != 2) {
      e.classList.add('invalid');
      return;
    }
    let hour = parseInt(parts[0]);
    if (hour < 0 || hour >= 24) {
      e.classList.add('invalid');
      return;
    }
    let minute = parseInt(parts[1]);
    if (minute < 0 || minute >= 60) {
      e.classList.add('invalid');
      return;
    }
  }
}

